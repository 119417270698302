<ngx-spinner *ngIf="isLoading" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple">
</ngx-spinner>
<motif-modal-title>
  <div class="usertitle"> {{title}}</div>
  <button class="close-btn" motifIconButton aria-label="Close modal" [motif-modal-close]="false">
    <motif-icon class="motif-modal-header-close-icon" [src]="'/assets/icons/navigation/ic_close_24px.svg'"></motif-icon>
  </button>
</motif-modal-title>
<div class="divstyle">
  <motif-modal-content *ngIf="newUserForm">
    <form [formGroup]="newUserForm">
      <motif-form-field>
        <div *ngIf="userId==undefined">
          <label motifLabel>Add/Search User name</label>
          <input id="user" (keyup)="loadUsers($event)" motifInput type="text" formControlName="user" [motifTypeahead]="dynamicDataSet"
            validationInteraction="touched" class="fontstyle"  />
          <motif-error *ngIf="validator('user')"> Required field</motif-error>
          <motif-error *ngIf="validateUser('user')"> Invalid User</motif-error>
        </div>
        <div *ngIf="userId!=undefined">
          <label motifLabel>Add/search user name</label>
          <input class="fontstyle" disabled="true" motifInput type="text" [formControl]="newUserForm.controls['user']"
            [motifTypeahead]="dynamicDataSet" motifInput [hideClear]="true" />
            <motif-error *ngIf="validator('user')"> Required field</motif-error>
        </div>
      </motif-form-field>

      <div formArrayName="workSpaceRoles" *ngFor="
      let workSpaceRole of newUserForm.get('workSpaceRoles')['controls'];
      let j = index
    ">
        <div class="three-columns-grid">
          <div [formGroupName]="j" >
            <div class="engagement-details">
              <motif-form-field>
                <label class="fontstyle" motifLabel>
                  Project Name
                  <motif-error class="motiferror" *ngIf="
                  workSpaceRole.controls.engagement?.hasError(
                    'engagementSelected') &&
                    workSpaceRole.controls.engagement.touched">
                    Project Name (Already selected)
                  </motif-error>
                </label>
                <div class="custom-select">
                <motif-select  [sortComparator]="true"  formControlName="engagement" id="engagement_{{ j }}" required>
                  <motif-option value=""
                    i18n="Add workspace Country Select One | Add workspace Country Select One dropdown option@@addWSWorkspaceCountrySelectOneDDLOption">Select
                    one</motif-option>
                  <motif-option *ngFor="let workspace of workspaceList" [value]="workspace?.id">
                    {{ workspace?.name }}
                  </motif-option>
                </motif-select>
                </div>
                <motif-error *ngIf="
                workSpaceRole.controls.engagement.hasError(
                  'engagementSelected')=='' &&
                  workSpaceRole.controls.engagement.dirty"> Required field
                </motif-error>
              </motif-form-field>
          
            </div>
          </div>
          <div [formGroupName]="j" >
            <div class="engagement-details">
              <motif-form-field>
                <label class="fontstyle" motifLabel for="role">
                  Role
                </label>
                <motif-select class="fontstyle" [sortComparator]="true" formControlName="role" id="role_{{ j }}">
                  <motif-option
                    i18n="Add workspace Country Select One | Add workspace Country Select One dropdown option@@addWSWorkspaceCountrySelectOneDDLOption">Select
                    one</motif-option>
  
                  <motif-option *ngFor="let role of roles" [value]="role?.id">
                    {{ role?.name }}
                  </motif-option>
                </motif-select>
                <motif-error *ngIf="workSpaceRole.controls.role?.invalid &&
                  workSpaceRole.controls.role?.touched"> Required field</motif-error>
              </motif-form-field>
            </div>
          
          </div>
          <div  class="deleteAddstyle" [formGroupName]="j">
            <span class="spanclass" *ngIf="newUserForm.controls.workSpaceRoles.controls.length > 1"
              aria-label="Delete" title="Delete workspace role" (click)="deleteRole(j)"
              i18n="Access Rights | Add User label@@quesSetupTableDeleteHeader">
              <motif-icon [src]="'/assets/icons/action/ic_delete_24px.svg'"></motif-icon>
            </span>

            <span *ngIf="
                j + 1 === newUserForm?.controls?.workSpaceRoles?.controls?.length
              " aria-label="Add" title="Add new workspace role"
              (click)="addNewRole()">
              <motif-icon [src]="'/assets/icons/content/ic_add_24px.svg'"></motif-icon>

            </span>
          </div>
        </div>
      </div>
    </form>

  </motif-modal-content>
</div>
<motif-modal-actions>
  <button class="submit-btn" motifButton type="submit" [disabled]="newUserForm?.invalid || (validateUser('user') &&  saveBtnName=='Add User')"
    (click)="saveUserRoles(newUserForm)" color="primary-alt">
    {{ saveBtnName }}

  </button>

  <button motifButton motif-modal-close color="secondary" aria-label="Cancel" role="button"> {{ closeBtnName }}</button>
</motif-modal-actions>


  