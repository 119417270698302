<div class="layout-container">
  <div>
    <div class="layout-addproject">
      <div class="layout-addproject-header-left">Hello, {{currentUser}}!</div>
      <div class="layout-addproject-header-content">
        <div class="layout-addproject-header-right">Last login activity</div>
        <div class="layout-addproject-header-right-sub">{{ lastLoginActivity | date:'MMMM dd, yyyy' }}</div>
      </div>
    </div>
    <hr class="bottom-border">
    <div class="layout-project-card">
      <div>
        <h3 class="header">Workspace Listing</h3>
        <sub class="sub-header">See all your current projects in one place.​</sub>
      </div>
      <div *ngIf="hasAddWorkspaceEntitlement(['AW'])">
        <button class="add-project" motifButton color="primary" size="medium" tooltip-color="alternate" placement="auto"
          [hideIconClose]="true" [hideArrow]="false" 
          motif-tooltip="Enables you to create a new project. Following inputs are required: Client name, Project name, Industry, City, Country."
          (click)="OpenCreateWorkspaceModel()" i18n="Home Add workspace button | Add Workspace button@@homeAddWSBtn">
          <motif-icon class="add-icon" [src]="'/assets/icons/content/ic_add_24px.svg'"></motif-icon>
          Add Project
        </button>
      </div>
    </div>
  </div>

  <div>
    <div class="layout-card">
      <div *ngFor="let workspace of workspaces" class="layout-workspace">
        <motif-card>
          <motif-card-header border="none">
            <div class="motif-row motif-between-xs">
              <div class="motif-col-xs-3 col-xs-3 motif-middle-xs">
                <span class="motif-h3 card-workspace-name" [title]="workspace.name"
                  [ngClass]="workspace.isProvisioned?'cursor: pointer':'disable-card'"
                  (click)="workspace.isProvisioned && setSelectedWorkspace(workspace)">{{workspace.name}}
                </span>
              </div>
              <div class="motif-col-xs-1 col-xs-1 motif-middle-xs motif-end-xs">
                <div motifDropdown placement="bottom-right">
                  <button motifIconButton aria-label="icon-button" motifDropdownToggle id="motifExampleDropdown1"
                    class="background-none">
                    <motif-icon [src]="'/assets/icons/navigation/ic_more_vert_24px.svg'"></motif-icon>
                  </button>
                  <div motifDropdownMenu class="motif-dropdown-menuCss">
                    <button motifDropdownItem class="edit-btn"
                      [ngClass]="{'disable-icon': !hasWorkspaceEntitlement(workspace.guid,'EW')}"
                      (click)="hasWorkspaceEntitlement(workspace.guid, 'EW')? OpenUpdateWorkspaceModel(workspace.guid): false">
                      <span>Edit</span>
                    </button>
                    <button motifDropdownItem
                      [ngClass]="{'disable-icon': !hasWorkspaceEntitlement(workspace.guid,'DW')}"
                      (click)="hasWorkspaceEntitlement(workspace.guid, 'DW')? OpenDeleteConfirmModal(workspace): false">
                      <span>Delete</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </motif-card-header>
          <motif-card-body class="layout-card-body" [ngClass]="workspace.isProvisioned?'':'disable-card'">
            <motif-chip [removeable]="false" class="motif-chip-text" [chipAriaLabel]="workspace.clientName"
              [title]="workspace.clientName">{{workspace.clientName}}
            </motif-chip>
          </motif-card-body>
          <motif-card-footer class="layout-card-footer" [ngClass]="workspace.isProvisioned?'':'disable-card'">
            {{workspace.country.name}} | Last modified on {{workspace.modifiedOn | date : 'MMM dd, yyyy' }}
          </motif-card-footer>
        </motif-card>
      </div>
    </div>
  </div>
</div>