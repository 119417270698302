export class QuestionBuilder {
  functionalAreas: FunctionalArea[];
}

export class FunctionalArea {
  name: string;
  guid: string;
  id: number;
  isInScope: boolean;
  questionMetadatas: QuestionMetadata[];
  weightedScore: number;
  isManualOverride: boolean;
  overriddenWeightedScore: number;

  // Owner Details
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  comments: string;
  order: number;
}

export class QuestionMetadata {
  id: number;
  functionalArea: FunctionalArea;
  description: string;
  descriptionJson: string;
  weightage: number;
  order: number;
  isInScope: boolean;
  observation: string;
  observationJson: string;
  recommendation: string;
  recommendationJson: string;
  responseType: number;
  impact: number;
  effort: number;
  timing: number;
  comment: string;
  sessionNote: string;
  heatmapNote: string;
  showInHeatmap: boolean;
  questionNumber: number;
  guid: string;
  fareaGuid: string;
  originCountry: string;
  exchange: string;
  industry: string;
  dataSaved = false;
  showDescriptionInPpt: boolean;
  questionComments: [];
  AQ_obvsCommentlength: number;
  AQ_recCommentlength: number;
  enableExecutiveSummary:boolean;
  executiveSummary:ExecutiveSummary;
  patternError:boolean
  requiredError:boolean
  spaceError:boolean
  keywordReqdError:boolean;
  startDateReqdError:boolean;
  dueDateReqdError:boolean;
  stageReqdError:boolean;
  startDateIssue:boolean;
  nextStepNewLineError:boolean;
  // nextStepNewLineError:boolean;
  // nextStepNewLineError:boolean;

}
 export class ExecutiveSummary{
  durationOfWorkId:any
  stageId:any;
  firstHeaderText:string;
  secondHeaderText:string;
  recommendationNextSteps:string;
 }