import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-actionButton-renderer',
  templateUrl: './action-button-render.component.html',
  styleUrls: ['./action-button-render.component.css']
})

export class ActionButtonRendererComponent implements ICellRendererAngularComp {
 
  public params: any;
  agInit(params: any): void { 
    this.params = params;
  }  
 
  isPopup(): boolean { 
    return true; 
  } 

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onEdit($event) {
    if (this.params[0].onEdit instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data
      }
      this.params[0].onEdit(params);
    }
  }
  onDelete($event){
    if (this.params[0].onDelete instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data
      }
      this.params[0].onDelete(params);
    }
  }
}
