import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SettingsProvider } from '../settingsProvider';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/assets/client-config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.apiUrl + '/api/auth/';
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  user: any;

  constructor(private http: HttpClient, private settings: SettingsProvider, private cookieService: CookieService) { }

  onPremlogin() {
    // return this.http.post(this.baseUrl + 'login', {}).pipe(
    //   map((response: any) => {
    //     this.user = response;
    //     if (this.user) {
    //       this.cookieService.set('ipoeauthtoken', this.user.token);
    //       // localStorage.setItem('ipoeauthtoken', this.user.token);
    //       this.decodedToken = this.jwtHelper.decodeToken(this.user.token);
    //     }
    //   })
    // );
  }

  onPremloggedIn() {
    // const token = localStorage.getItem('ipoeauthtoken');
    // if (this.cookieService.check('ipoeauthtoken')) {
    // const token = this.cookieService.get('ipoeauthtoken');
    // this.decodedToken = this.jwtHelper.decodeToken(token);
    // return !this.jwtHelper.isTokenExpired(token);
    // } else {
    //   if (this.user) {
    //     this.decodedToken = this.jwtHelper.decodeToken(this.user.token);
    //     return !this.jwtHelper.isTokenExpired(this.user.token);
    //   } else {
    //     return false;
    //   }
    // }
  }
}
