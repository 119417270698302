<form #updateheatmapNoteForm="ngForm" class="update-heatmap-form" (ngSubmit)="saveQuestion(updateheatmapNote)" autocomplete="off" *ngIf="!loading"
  novalidate>
  <motif-modal-title>
    <h3> {{title}} </h3>
    <button class="close-btn" motifIconButton aria-label="Close modal" [motif-modal-close]="false">
      <motif-icon class="motif-modal-header-close-icon"
        [src]="'/assets/icons/navigation/ic_close_24px.svg'"></motif-icon>
    </button>
  </motif-modal-title>
  <motif-modal-content>
    <div *ngFor="let alert of alerts">
      <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout" [dismissible]="true">{{ alert.msg }}</alert>
    </div>
    <div class="motif-row">
      <div class="motif-col-xs-1">
        <motif-form-field>
          <label motifLabel aria-disabled="true">Heatmap ID</label>
          <input motifInput type="text" aria-label="Input Field" disabled value={{dataParams.data.id}} />
        </motif-form-field>
      </div>
      <div class="motif-col-xs-2">
        <motif-form-field>
          <label motifLabel aria-disabled="true">Functional area</label>
          <input motifInput type="text" aria-label="Input Field" disabled value={{newQuestion.functionalArea.name}} />
        </motif-form-field>
      </div>
      <div class="motif-col-xs-1">
        <motif-form-field>
          <label motifLabel aria-disabled="true">Question ID</label>
          <input motifInput type="text" aria-label="Input Field" disabled value={{dataParams.data.quesSeqId}} />
        </motif-form-field>
      </div>
    </div>
    <motif-form-field>
      <label motifLabel aria-disabled="false">Summarised text for Heatmap/Bubble chart</label>
      <input motifInput [(ngModel)]="newQuestion.heatmapNote" name="heatmapNote" type="text" aria-label="Input Field" maxlength="180" (input)="validationCheck()"
        required />
        <div class="error-msg" *ngIf="spaceflagSummary">
          <span class="warning-icon"></span> {{spaceMsg}}
        </div>
        <div class="error-msg" *ngIf="patternFlagSummary">
          <span class="warning-icon"></span> {{patternMsg}}
        </div>
    </motif-form-field>
    <motif-form-field>
      <h2>Observation</h2>
      <motif-rich-text-editor format="json" [(ngModel)]="newQuestion.observationJson" name="observation" (onEditorChanged)="observationText($event)" placeholder="Observation"  class="text-editor" ngDefaultControl  [modules]="{
        toolbar: [['bold', 'italic', 'underline',{ 'color': [] }, { 'list': 'bullet' }, { 'list': 'ordered'}]]
    }">
      </motif-rich-text-editor>

  <div class="error-msg-container">
    <div class="error-messages">
      <motif-error *ngIf="newQuestion.observation?.invalid &&
      newQuestion.observation?.touched">
        Required field
      </motif-error>
      <motif-error *ngIf="observationLimitExceeded">
        <span class="body4 error-msg required-labelmsg">
          Maximum word limit exceeded</span>
        </motif-error>
        <motif-error *ngIf="spaceflagObservation">
          {{spaceMsg}}
        </motif-error>
        <motif-error *ngIf="patternFlagObservation">
          {{patternMsg}}
        </motif-error>

    </div>

    <span [ngClass]="{'error':observationLimitExceeded}">
      {{ observationLength }}/2000
    </span>
  </div>

    </motif-form-field>
    <motif-form-field>
      <h2>Recommendation</h2>
      <motif-rich-text-editor format="json" [(ngModel)]="newQuestion.recommendationJson" name="recommendation" (onEditorChanged)="recommendationText($event)" placeholder="Recommendation" class="text-editor" ngDefaultControl
      [modules]="{
        toolbar: [['bold', 'italic', 'underline', { 'color': [] }, { 'list': 'bullet' }, { 'list': 'ordered'}]]
    }">
      </motif-rich-text-editor>
      
    <div class="error-msg-container">
      <div class="error-messages">
        <motif-error *ngIf="newQuestion.recommendation?.invalid &&
        newQuestion.recommendation?.touched">
          Required field
        </motif-error>
        <motif-error *ngIf="recommendationLimitExceeded" >
          <span class="body4 error-msg required-labelmsg">
            Maximum word limit exceeded</span>
          </motif-error>
          <motif-error *ngIf="spaceflagRecommendation">
            {{spaceMsg}}
          </motif-error>
          <motif-error *ngIf="patternFlagRecommendation">
            {{patternMsg}}
          </motif-error>
      </div>
  
      <span [ngClass]="{'error':recommendationLimitExceeded}">
        {{ recommendationLength }}/2000
      </span>
    </div>

    </motif-form-field>
    <div class="motif-col-xs-6 executive-summary" *ngIf="newQuestion.enableExecutiveSummary == true">
      <!-- <motif-toast [autoHide]="false" type="warning" [show]="true" [inline]="true">Please
        ensure to review the executive summary fields in assessment screen as any changes made here 
        might impact the executive summary content</motif-toast> -->
      <motif-inline-message [type]="'warning'" class="executivesummarymessage">Please
          ensure to review the executive summary fields in assessment screen as any changes made here 
          might impact the executive summary content</motif-inline-message>

    </div>
  </motif-modal-content>
  <motif-modal-actions>
    <button type="submit" motifButton [disabled]="!updateheatmapNoteForm?.dirty || updateheatmapNoteForm?.invalid || recommendationLimitExceeded || observationLimitExceeded || spaceflagObservation|| patternFlagObservation||spaceflagRecommendation||patternFlagRecommendation||spaceflagSummary||patternFlagSummary" color="primary-alt" [motif-modal-close]="true" aria-label={{saveBtnName}}
      role="button">{{saveBtnName}}</button>
    <button motifButton motif-modal-close color="secondary" aria-label={{closeBtnName}} role="button">{{closeBtnName}}</button>
  </motif-modal-actions>
</form>