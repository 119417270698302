<motif-modal-title>
    <h3 class="ma-0 flex-v-center">
        <button class="close-btn" class="focus-button">
        <motif-icon class="text-error mr-2" [src]="'/assets/icons/alert/ic_error_24px.svg'"></motif-icon>

        </button>
        Session Expiry
    </h3>
</motif-modal-title>

<motif-modal-content>
    <p>
        If there is no activity detected, you will be logged out due to prolonged inactivity.
    </p>
</motif-modal-content>

<motif-modal-actions class="d-flex justify-content-end">
    <button (click)="logout($event)" class="logout" motifButton motif-modal-close color="warn" role="button">Logout ({{sec}})</button>
    <button motifButton (click)="continueWorking()" color="primary-alt" [motif-modal-close]="true"  role="button">Stay logged in</button>
</motif-modal-actions>