import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { SettingsProvider } from '../settingsProvider';
import { QuestionMaster, QuestionFilter } from '../models/questionMaster.model';
import { environment } from 'src/assets/client-config';
// import { Body } from '@angular/http/src/body';
@Injectable({
  providedIn: 'root'
})
export class QuestionMasterService {
  private questionMasterApiUrl =
    environment.apiUrl + '/api/questionMaster/';
    public gridRefresh = new BehaviorSubject<boolean>(false);
  // ?FunstionalAreaMasterId=1&OriginCountry=2,3&DestinationExchange=5,6&Industry=7,8';
  constructor(private http: HttpClient, public settings: SettingsProvider) {}

  getQuestionMaster(
    // questionFilter: QuestionFilter
    questionFilter: QuestionFilter
  ): Observable<QuestionMaster[]> {
    // Parameters obj-
    let params = new HttpParams();
    params = params.append(
      'funstionalAreaMasterId',
      questionFilter.funstionalAreaMasterId
    );
    params = params.append('originCountry', questionFilter.originCountry);
    params = params.append(
      'destinationExchange',
      questionFilter.destinationExchange
    );
    params = params.append('industry', questionFilter.industry);

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: params
    };

    return this.http
      .get<QuestionMaster[]>(
        this.questionMasterApiUrl + 'GetQuestionMaster',
        options
      )
      .pipe(tap(data => data));
  }

  saveQuestion(question) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post<QuestionMaster>(
        this.questionMasterApiUrl + 'SaveQuestionMaster',
        question,
        options
      )
      .pipe(tap(data => data));
    // .pipe(catchError(this.handleError<QuestionMaster>('saveQuestion')));
  }

  getQuestion(id): Observable<QuestionMaster> {
    return this.http
      .get<QuestionMaster>(this.questionMasterApiUrl + 'GetQuestion/' + id)
      .pipe(tap(data => data));
  }

  updateQuestion(question: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.put<QuestionMaster>(
      this.questionMasterApiUrl + 'UpdateQuestionMaster',
      question,
      options
    );
  }

  deleteSingleQuestionMaster(questionId: any) {
    // const url = this.questionMasterApiUrl + 'DeleteQuestionMasterById/' + questionId;
    // const options = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    // };
    // return this.http.put<QuestionMaster>(url, options);
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.delete<number>(
      this.questionMasterApiUrl + 'DeleteQuestionMasterById/' + questionId,
      options
    );
  }

  deleteMultipleQuestionMaster(questionIds: any) {
    const url = this.questionMasterApiUrl + 'DeleteMultipleQuestions';
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.put(url, questionIds, options);
  }

  getQuestionMasterById(questionId: any) {
    const url = this.questionMasterApiUrl + 'GetQuestionById/' + questionId;
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get<QuestionMaster>(url, options);
  }

  autosaveQuestionMasterOrder(quesId: number, order: number) {
    const url = this.questionMasterApiUrl + 'SaveQuestionMasterOrder/' + quesId + '/' + order;
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.put(url, options);
  }
}
