<motif-modal-title class="title">
     About IPO Enabler
  <button color="primary-alt" motifIconButton class="closebutton" aria-label="Close modal" [motif-modal-close]="false">
    <motif-icon class="motif-modal-header-close-icon" [src]="'/assets/icons/navigation/ic_close_24px.svg'"></motif-icon>
  </button>
</motif-modal-title>
<motif-modal-content> 
  <div class="textabout">
      Version: IPOE.5.1.0  
  <div class="textsection">
  <motif-icon class="copyrighticon" [src]="'/assets/icons/action/ic_copyright_24px.svg'"></motif-icon> 2024 EY, LLP IPO Enabler    
  </div>
</div>  
</motif-modal-content>
<motif-modal-actions>
  <button class="cancelbutton" motifButton motif-modal-close color="secondary" aria-label="Cancel" role="button" >Close</button>
</motif-modal-actions> 