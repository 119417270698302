import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../session-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-renew-session',
  templateUrl: './renew-session.component.html',
  styleUrls: ['./renew-session.component.css']
})
export class RenewSessionComponent implements OnInit {
  sec = 0;
  constructor(private sessionService: SessionService) {
    this.sessionService.inactiveSessionExpiry.subscribe(item=>{
      this.sec = item;
    })
   }
  ngOnInit(): void {
  }

  logout(e){
    this.sessionService.logoutSession();
  }
  continueWorking(){
    this.sessionService.renewSession();
  }
}
