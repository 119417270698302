import { Component, OnInit, Inject } from '@angular/core';
import { QuestionMetadata } from 'src/app/readiness/questionairrelibrary/questionMetadata';
import { QuestionService } from 'src/app/readiness/questionairrelibrary/question.service';
import { QuestionService as QService } from 'src/app/readiness/questionnaireclientview/question.service';
import { PubSubService } from 'src/app/_services/pubsub.service';
import { MOTIF_MODAL_DATA, MotifModal } from '@ey-xd/ng-motif';
import { UtilityService } from 'src/app/_services/utility.service';
import { ValidationConstant } from 'src/app/constants/validation-constant';

@Component({
  selector: 'app-update-heatmap',
  templateUrl: './update-heatmap.component.html',
  styleUrls: ['./update-heatmap.component.css']
})
export class UpdateHeatmapComponent implements OnInit {
  dataParams: any;
  newQuestion: any;
  title: string;
  closeBtnName: string;
  saveBtnName: string;
  questionName: string;
  id: number;
  functionalArea: any[];
  alerts: any[] = [];
  isQuestionMasterMode = true;
  addQuestionMode = false;
  loading = false;
  observationLength = 0;
  recommendationLength = 0;
  observationLimitExceeded: boolean = false;
  recommendationLimitExceeded: boolean = false;
  spaceflagObservation:boolean=false;
  patternFlagObservation:boolean=false;
  spaceflagRecommendation:boolean=false;
  patternFlagRecommendation:boolean=false;
  spaceflagSummary:boolean=false;
  patternFlagSummary:boolean=false;
  pattern1=ValidationConstant.Pattern;
  patternMsg=ValidationConstant.PatternIssueMsg;
  spaceMsg=ValidationConstant.SpaceIssueMsg;
  constructor(
    public modal: MotifModal,
    @Inject(MOTIF_MODAL_DATA) motifData: any,
    private getQuestionService: QuestionService,
    private updateService: QService,
    private broadcaster: PubSubService,
    private utilityService: UtilityService,
  ) {
    this.title = motifData?.initialState.title;
    this.isQuestionMasterMode = motifData?.initialState.isQuestionMasterMode;
    this.addQuestionMode = motifData?.initialState.addQuestionMode;
    this.id = motifData?.initialState.id;
    this.dataParams = motifData?.initialState.dataParams;
    this.closeBtnName = motifData?.initialState.closeBtnName;
    this.saveBtnName = motifData?.initialState.saveBtnName;
  }

  ngOnInit() {
        this.loading = true;
        this.loadQuestion(this.id);

  }

  loadQuestion(id: any) {
    this.getQuestionService.getQuestion(id).subscribe(
      question => {
        this.newQuestion = question;
        this.loading = false;
        this.observationLength = this.newQuestion.observation.length;
        this.recommendationLength = this.newQuestion.recommendation.length;
        // this.getAllDataForEditpopup();
      },
      error => {
        this.DisplayMessage('Server error!!!', 'danger');
      }
    );
  }
/*
  getAllDataForEditpopup() {
          this.loadEditQuestionData();
  }

  loadEditQuestionData() {
    this.loading = false;
  }
*/
  saveQuestion(formValues: any) {
      this.saveQuestionMetadata(formValues);
  }

  saveQuestionMetadata(formValues: any) {
    // if (this.id !== undefined) {
    if (!this.addQuestionMode) {
     const questionMetadata = new QuestionMetadata();
     questionMetadata.id = this.newQuestion.id;
     questionMetadata.description = 'detail';
     questionMetadata.heatmapNote = this.newQuestion.heatmapNote.replace('\n', '');
     questionMetadata.observation = this.newQuestion.observation.replace('\n', '');
     questionMetadata.recommendation = this.newQuestion.recommendation.replace('\n', '');
     questionMetadata.observationJson = this.newQuestion.observationJson;
     questionMetadata.recommendationJson = this.newQuestion.recommendationJson;

      this.updateService.updateQuestionDetails(questionMetadata).subscribe(
        () => {
          this.broadcaster.$pub('REFRESH_HEATMAP_GRID', { Action: 'updatesuccess' });
        },
        error => {
          if(error.includes("validation errors"))
            this.broadcaster.$pub('REFRESH_HEATMAP_GRID', { Action: 'validationfailed' });
          else
          this.broadcaster.$pub('REFRESH_HEATMAP_GRID', { Action: 'updatefailed' });
        }
      );
    }
  }


  DisplayMessage(message: string, type: string) {
    this.alerts.push({
      type: type,
      msg: message
    });
  }

  observationText(e) {
    if (e.text) {
      e.text= e.text.substring(0, e.text.length - 1);
      this.spaceflagObservation= e.text.startsWith(' ') || 
      e.text.endsWith(' ')||e.text.startsWith('\t') || 
      e.text.endsWith('\t');
      let pattern =this.pattern1;
      this.patternFlagObservation= !pattern.test(e.text);
      this.observationLength = e.text.length;
      if(this.observationLength >2000){
        this.observationLimitExceeded = true;
      }
      else{
        this.observationLimitExceeded = false;
      }
      this.newQuestion.observation = e.text;
    }
  }
  recommendationText(e) {
    if (e.text) {
      e.text= e.text.substring(0, e.text.length - 1);
      this.spaceflagRecommendation= e.text.startsWith(' ') || 
      e.text.endsWith(' ')||e.text.startsWith('\t') || 
      e.text.endsWith('\t');
      let pattern =this.pattern1;
      this.patternFlagRecommendation= !pattern.test(e.text);
      this.recommendationLength = e.text.length;
      if(this.recommendationLength >2000){
        this.recommendationLimitExceeded = true;
      }
      else{
        this.recommendationLimitExceeded = false;
      }
      this.newQuestion.recommendation = e.text;
    }
  }
  validationCheck() {
    if (this.newQuestion.heatmapNote) {
      this.spaceflagSummary= this.newQuestion.heatmapNote.startsWith(' ') || 
      this.newQuestion.heatmapNote.endsWith(' ')||this.newQuestion.heatmapNote.startsWith('\t') || 
      this.newQuestion.heatmapNote.endsWith('\t');
      let pattern =this.pattern1;
      this.patternFlagSummary= !pattern.test(this.newQuestion.heatmapNote);
    }
  }
}
