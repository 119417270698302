import { Component } from '@angular/core';
import { ModalSizes, MotifModal, MotifModalRef } from '@ey-xd/ng-motif';

@Component({
  selector: 'app-key-activities-gridrenderer',
  template: `
    <div class="gridquestionlabel" *ngIf="params?.column === 'status'">
      <span class="status-container"><motif-icon [class]="statusIcon" class="status-icon"></motif-icon> {{ statusText }} </span>
    </div>
    <div *ngIf="params?.column === 'action'">
        <button motifIconButton class="action-button" aria-label="icon-button" size="small" (click)="onEdit($event)">
            <motif-icon class="edit-icon"></motif-icon>
        </button>
        <button motifIconButton class="action-button" aria-label="icon-button" size="small" (click)="onDelete($event)">
            <motif-icon class="delete-icon"></motif-icon>
        </button>
    </div>
  `,
  styles: [
    ` .edit-icon {
        background: url('/assets/icons/ic_edit_24px.svg') center/contain no-repeat;
        display: block;
    }
    .action-button:hover{
      background: none!important;
    }
    .action-button:focus{
      background: none!important;
    }
    .delete-icon {
      background: url('/assets/icons/ic_delete_24px.svg') center/contain no-repeat;
      display: block;
    }
    .status-container {
        display: flex;
        align-items: center;
        font-family: EYInterstate;
    }
    .status-icon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }
    .statusnotstarted {
        background: url('/assets/icons/disabled.svg') center/contain no-repeat;
        display: block;
    }
    .statusinprogress {
        background: url('/assets/icons/warning.svg') center/contain no-repeat;
        display: block;
    }
    .statuscompleted {
        background: url('/assets/icons/success.svg') center/contain no-repeat;
        display: block;
    }
    .motif-icon-button:hover
    {
      --btn-icon--bg-color: transparent!important;
    }
    .motif-icon-button:focus
    {
      --btn-icon--bg-color: transparent!important;
    }`
  ]
})
export class KeyActivitiesGridRendererComponent {
  public params: any;
  public motifModalRef?: MotifModalRef<any>;
  statusIcon: string = '';
  statusText: string = '';

  constructor(
    public motifmodal: MotifModal
  ) { }

  agInit(params: any): void {
    this.params = params;
    if(this.params.data.status == 1) {
        this.statusIcon = 'statusnotstarted';
        this.statusText = 'Not Started';
    }
    if(this.params.data.status == 2) {
        this.statusIcon = 'statusinprogress';
        this.statusText = 'In progress';
    }
    if(this.params.data.status == 3) {
        this.statusIcon = 'statuscompleted';
        this.statusText = 'Completed';
    }
  }

  onEdit(event) {
    if (this.params.onEdit instanceof Function) {
        const params = {
          event: event,
          rowData: this.params.node.data
        }
        this.params.onEdit(params);
    }
  }

  onDelete(event) {
    if (this.params.onDelete instanceof Function) {
        const params = {
          event: event,
          rowData: this.params.node.data
        }
        this.params.onDelete(params);
    }
  }
}