import { Component, Inject } from '@angular/core';
// import { AdalService } from 'adal-angular4';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from '../_services/auth.service';
// import { onPremises } from '../../assets/client-config.json';
import { SettingsProvider } from '../settingsProvider';
import { environment } from 'src/assets/client-config';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
  onPremises: boolean;

  constructor(public auth: MsalService, public router: Router, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) {
    this.onPremises = environment.onPremises;
  }

  login() {
    this.router.navigateByUrl('/home');
  }
}
