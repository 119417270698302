import { FunctionalAreaMaster } from './functionalareamaster.model';

export class QuestionMaster {
  id: number;
  functionalAreaMaster: FunctionalAreaMaster;
  description: string;
  descriptionJson: string;
  weightage: number;
  observation: string;
  recommendation: string;
  guid: string;
  originCountry: string;
  exchange: string;
  industry: string;
  order: number;
}

export class QuestionFilter {
  funstionalAreaMasterId: string;
  originCountry: string;
  destinationExchange: string;
  industry: string;
}
