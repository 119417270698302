import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { Country } from '../models/country';
import { Industry } from '../models/industry';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SettingsProvider } from '../settingsProvider';
import { tap } from 'rxjs/internal/operators/tap';
import { Exchange } from '../models/exchange';
import { environment } from 'src/assets/client-config';
// import { forkJoin } from 'rxjs/observable/forkJoin';

@Injectable({
  providedIn: 'root'
})
export class BenchmarkService {
  private benchmarkApiUrl =
    environment.apiUrl + '/api/Benchmark/';

  constructor(private http: HttpClient, private setting: SettingsProvider) {}

  getBenchmarkDataByQues(questionId: any, filtersVM: any) {
    const url = this.benchmarkApiUrl + 'GetBenchmarkDataByQues/' + questionId;
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(url, filtersVM, options);
  }

  sendBenchmarkingData(engagementGuid) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post(
        this.benchmarkApiUrl + 'SendToBenchmarkDB/' + engagementGuid,
        null,
        options
      )
      .pipe(tap(data => data));
    // .pipe(catchError(this.handleError<QuestionMaster>('saveQuestion')));
  }
}
