import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
// import { AdalService } from 'adal-angular4';
import { AuthService } from '../_services/auth.service';
import { SettingsProvider } from '../settingsProvider';
import { environment } from 'src/assets/client-config';
import { SessionService } from './session-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  sessionId;
  constructor(private sessionService: SessionService, private settings: SettingsProvider) { 
    this.sessionService.startSession();
    this.sessionService.sessionId.subscribe(item =>{
      this.sessionId = item
    })
  }

  canActivate(): boolean {
    if(this.sessionId){
      return true;
    }
  }
}
