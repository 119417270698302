import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { QuestionMetadatService } from '../questionmetadata.service';
//import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { QuestionMetadata, FunctionalArea } from '../questionMetadata';
import { QuestionService } from '../question.service';
import { Labels } from 'src/app/locale/en/labels.en';
import { Country } from 'src/app/models/country';
import { Industry } from 'src/app/models/industry';
import { Exchange } from 'src/app/models/exchange';
import { CommonService } from 'src/app/_services/common.service';
import { QuestionMasterService } from 'src/app/_services/question-master.service';
import { FunctionalareamasterService } from 'src/app/_services/functionalareamaster.service';
import { QuestionMaster } from 'src/app/models/questionMaster.model';
import { PubSubService } from '../../../_services/pubsub.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MOTIF_MODAL_DATA, MotifModal } from '@ey-xd/ng-motif';
import { switchMap } from 'rxjs-compat/operator/switchMap';
import { ToasterService } from 'src/app/shared/toast/toaster.service';
import { ValidationConstant } from 'src/app/constants/validation-constant';

@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.scss']
})
export class CreateQuestionComponent implements OnInit {
  newQuestionForm: UntypedFormGroup;
  newQuestion: any;
  title: string;
  closeBtnName: string;
  saveBtnName: string;
  questionName: string;
  id: number;
  functionalArea: any[];
  country: Country[];
  industries: Industry[];
  criticality: any[];
  exchanges: Exchange[];
  alerts: any[] = [];
  defaultselected: any;
  defaultselectedWeightage: any;
  functionalAreaSelected: string;
  functionalAreaSelectedId: number;
  weightingList: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  isQuestionMasterMode = true;
  addQuestionMode = true;
  newQuestionMaster: any;
  loading = false;
  defaultmultiselectLabel = Labels.DefaultmultiselectLabel;
  selectedFuncAreaList = [];

  selectedCountryid: any;
  selectedOriginCountry = [];

  selectedIndustryid: any;
  selectedIndustry = [];

  selectedExchangeid: any;
  seletedDestExchange = [];
  disableUpdateQuestionButton: boolean;

  selectedIndustries;
  selectedCountries;
  selectedExchanges;
  selectedCriticality;
  selectedFunctionalArea: any;
  questionLength = 0;
  observationLength = 0;
  recommendationLength = 0;
  questionLimitExceeded: boolean = false;
  observationLimitExceeded: boolean = false;
  recommendationLimitExceeded: boolean = false;
  spaceflagQuestion= false;
  patternFlagQuestion= false;
  spaceflagObservation= false;
  patternFlagObservation= false;
  spaceflagRecommendation= false;
  patternFlagRecommendation= false;
  functionalAreaName: string = '';
  recommendation: string;
  observation: string;
  description: string;
  isDescriptionRequired: boolean;
  isObservationRequired: boolean;
  isRecommendationRequired: boolean;
  pattern1=ValidationConstant.Pattern;
  patternMsg=ValidationConstant.PatternIssueMsg;
  spaceMsg=ValidationConstant.SpaceIssueMsg;
  constructor(
    @Inject(MOTIF_MODAL_DATA) data: any,
    private fb: FormBuilder,
    private questionMetadatService: QuestionMetadatService,
    private questionService: QuestionService,
    private commonservice: CommonService,
    private questionMasterService: QuestionMasterService,
    private fuctionalAreaMasterService: FunctionalareamasterService,
    private broadcaster: PubSubService,
    private utilityService: UtilityService,
    private toastr: ToasterService,
    private motifmodal: MotifModal
  ) {
    this.isQuestionMasterMode = data?.initialState.isQuestionMasterMode;
    this.addQuestionMode = data?.initialState.addQuestionMode;
    this.closeBtnName = data?.initialState.closeBtnName;
    this.saveBtnName = data?.initialState.saveBtnName;
    this.id = data?.initialState.id;
    this.title = data?.initialState.title;
  }

  ngOnInit() {
    this.generateQuestionForm();
    if (this.isQuestionMasterMode === true) {
      this.newQuestion = new QuestionMaster();
      this.loadFunctionalArea();

      if (this.addQuestionMode === false) {
        this.loading = true;
        this.loadQuestionMaster(this.id);
      } else {
        this.loadMultiSelectData();
      }
    } else {
      this.newQuestion = new QuestionMetadata();
      this.loadFunctionalArea();
      if (this.addQuestionMode === false) {
        this.loading = true;
        this.loadQuestion(this.id);
      } else {
        this.loadMultiSelectData();
      }
    }
    this.disableUpdateQuestionButton = false;
  }
  generateQuestionForm() {
    this.newQuestionForm = this.fb.group({
      functionalArea: ['', Validators.required],
      originCountry: ['', Validators.required],
      exchange: ['', Validators.required],
      industry: ['', Validators.required],
      descriptionJson: ['', Validators.required],
      observationJson: ['', Validators.required],
      recommendationJson: ['', Validators.required],
      weightage: ['']
    })
    if (!this.isQuestionMasterMode) {
      this.newQuestionForm.controls["weightage"].setValidators(Validators.required);
    }
    else {
      this.newQuestionForm.controls["weightage"].clearValidators();
    }
  }
  log(e) {
  }
  loadQuestion(id: any) {
    this.questionService.getQuestion(id).subscribe(
      question => {
        this.newQuestion = question;

        this.getAllDataForEditpopup();

      },
      error => {

        this.DisplayMessage('Server error!!!', 'danger');
      }
    );
  }

  loadFunctionalArea() {
    if (this.isQuestionMasterMode) {
      this.fuctionalAreaMasterService.getFunctionalAreaMaster().subscribe(
        functionalArea => {
          this.functionalArea = functionalArea;
        },
        error => {
          this.DisplayMessage(Labels.ModalCreateQuestionLoadFAError, 'danger');
        }
      );
    } else {
      this.questionService.getFunctionalAreaList().subscribe(
        functionalArea => {
          this.functionalArea = functionalArea as FunctionalArea[];
        },
        error => {

          this.DisplayMessage(Labels.ModalCreateQuestionLoadFAError, 'danger');
        }
      );
    }
  }

  loadMultiSelectData() {
    // Existing code not changed
    this.commonservice.getCountry().subscribe(
      res => {
        this.country = res as Country[];
      },
      error => {

        this.DisplayMessage(Labels.ServerErrorOnLoad, 'danger');
      }
    );
    this.commonservice.getExchange().subscribe(
      res => {
        this.exchanges = res as Exchange[];
      },
      error => {

        this.DisplayMessage(Labels.ServerErrorOnLoad, 'danger');
      }
    );
    this.commonservice.getIndustry().subscribe(
      res => {
        this.industries = res as Industry[];
      },
      error => {

        this.DisplayMessage(Labels.ServerErrorOnLoad, 'danger');
      }
    );
  }

  loadQuestionMaster(id: any) {
    this.questionMasterService.getQuestionMasterById(id).subscribe(
      (data: any) => {
        this.newQuestion = data;
        this.getAllDataForEditpopup();
        // this.loading = false;
      },
      (err: any) => {
      }
    );
  }

  getAllDataForEditpopup() {
    this.commonservice.getAllDataForEditQuestionLibrary().subscribe(
      responseList => {
        this.country = responseList[0];
        this.industries = responseList[1];
        this.exchanges = responseList[2];
        if (!this.addQuestionMode) {
          this.loadEditQuestionData();
        }
      },
      error => {
        this.DisplayMessage(Labels.ServerErrorOnLoad, 'danger');
      }
    );
  }

  loadEditQuestionData() {
    this.newQuestion.descriptionJson = this.utilityService.getDeltaJson(
      this.newQuestion.description,
      this.newQuestion.descriptionJson
    );

    this.newQuestion.observationJson = this.utilityService.getDeltaJson(
      this.newQuestion.observation,
      this.newQuestion.observationJson
    );

    this.newQuestion.recommendationJson = this.utilityService.getDeltaJson(
      this.newQuestion.recommendation,
      this.newQuestion.recommendationJson
    );
    this.description = this.newQuestion.description;
    this.observation = this.newQuestion.observation;
    this.recommendation = this.newQuestion.recommendation;
    // country
    let selectedCountryFinal = [];
    this.selectedCountryid = this.newQuestion['originCountry'];
    let selectedCountryIdsList = [];
    if (this.selectedCountryid !== null) {
      selectedCountryIdsList = this.selectedCountryid
        .split(',')
        .map(function (item) {
          return parseInt(item, 10);
        });

      selectedCountryFinal = this.country.filter(function (e) {
        return this.indexOf(e.id) !== -1;
      }, selectedCountryIdsList);


    }

    // exchange
    let selectedExchangeFinal = [];
    this.selectedExchangeid = this.newQuestion['exchange'];
    let selectedExIdsList = [];
    if (this.selectedExchangeid !== null) {
      selectedExIdsList = this.selectedExchangeid
        .split(',')
        .map(function (item) {
          return parseInt(item, 10);
        });
      selectedExchangeFinal = this.exchanges.filter(function (e) {
        return this.indexOf(e.id) !== -1;
      }, selectedExIdsList);


    }

    // industry
    let selectedIndustryFinal = [];
    this.selectedIndustryid = this.newQuestion['industry'];
    let selectedIdsList = [];
    if (this.selectedIndustryid !== null) {
      selectedIdsList = this.selectedIndustryid.split(',').map(function (item) {
        return parseInt(item, 10);
      });

      selectedIndustryFinal = this.industries.filter(function (e) {
        return this.indexOf(e.id) !== -1;
      }, selectedIdsList);


    }

    this.newQuestion.originCountry = selectedCountryFinal;
    this.newQuestion.exchangetext = selectedExchangeFinal;
    this.newQuestion.industrytext = selectedIndustryFinal;
    this.selectedCriticality = this.newQuestion.weightage;
    this.disableUpdateQuestionButton = this.newQuestion.enableBenchmarking;

    let fa: any = [];
    if (this.isQuestionMasterMode) {
      fa = this.functionalArea.filter(
        f => f.id === this.newQuestion.functionalAreaMaster.id
      );
    } else {
      if (this.newQuestion.functionalArea !== null) {
        fa = this.functionalArea.filter(
          f => f.id === this.newQuestion.functionalArea.id
        );
      }
    }
    if (fa.length > 0) {
      this.newQuestion.functionalArea = fa[0];
    }
    this.newQuestionForm.patchValue({
      functionalArea: this.newQuestion.functionalArea,
      originCountry: selectedCountryIdsList,
      exchange: selectedExIdsList,
      industry: selectedIdsList,
      descriptionJson: this.newQuestion.descriptionJson,
      observationJson: this.newQuestion.observationJson,
      recommendationJson: this.newQuestion.recommendationJson,
      weightage: this.newQuestion.weightage
    });
    this.loading = false;
  }

  changeInCountry(e) {
    this.selectedCountries = e.toString()
  }
  changeInDestination(e) {
    this.selectedExchanges = e.toString()
  }
  changeInIndustry(e) {
    this.selectedIndustries = e.toString()
  }
  changeInFuncArea(e) {
    this.functionalAreaName = e.name;
    // this.selectedFunctionalArea = e.toString();
  }
  changeInCriticality(e) {
    this.selectedCriticality = e.toString()
  }
  saveQuestion(formValues?: any) {
    this.newQuestionForm.value.originCountry = this.selectedCountries;
    this.newQuestionForm.value.exchange = this.selectedExchanges;
    this.newQuestionForm.value.industry = this.selectedIndustries;
    this.newQuestionForm.value.weightage = this.selectedCriticality;
    //this.newQuestionForm.value.functionalArea = this.selectedFunctionalArea;
    let payload;
    if (this.addQuestionMode) {
      payload = this.newQuestionForm.value;
      payload['description'] = this.description;
      payload['observation'] = this.observation;
      payload['recommendation'] = this.recommendation;
    }

    if (!this.addQuestionMode) {
      this.newQuestion.description = this.description;
      this.newQuestion.observation = this.observation;
      this.newQuestion.recommendation = this.recommendation;
      this.newQuestion.descriptionJson = this.newQuestionForm.value.descriptionJson;
      this.newQuestion.observationJson = this.newQuestionForm.value.observationJson;
      this.newQuestion.recommendationJson = this.newQuestionForm.value.recommendationJson;
      this.newQuestion.originCountry = this.selectedCountries;
      this.newQuestion.exchange = this.selectedExchanges;
      this.newQuestion.industry = this.selectedIndustries;
      this.newQuestion.weightage = this.selectedCriticality;
      payload = this.newQuestion;
    }
    if (this.isQuestionMasterMode) {
      this.saveQuestionMaster(payload);
    } else {
      this.saveQuestionMetadata(payload);
    }
  }
  questionText(e) {
    if (e.text) {
      e.text= e.text.substring(0, e.text.length - 1);
      this.spaceflagQuestion= e.text.startsWith(' ') || 
      e.text.endsWith(' ')||e.text.startsWith('\t') || 
      e.text.endsWith('\t');
      let pattern =this.pattern1;
      this.patternFlagQuestion= !pattern.test(e.text);
      //e.text = e.text.trim();
      this.questionLength = e.text.length;
      if (e.text.length > 0) {
        this.description = e.text;
        if (this.questionLength > 500) {
          //  this.questionLength = 500;
          this.questionLimitExceeded = true;
        }
        else {
          this.questionLimitExceeded = false;
        }
        this.isDescriptionRequired = false;
      } else {
        this.newQuestionForm.controls['descriptionJson'].setValue("");
        this.isDescriptionRequired = true;
      }
      if(this.spaceflagQuestion || this.patternFlagQuestion)
        {
        this.isDescriptionRequired = false;
        return;
        }
    } else {
      if(this.questionLength===0)
      this.isDescriptionRequired = true;
    }
    if(e.event == 'selection-change')
      this.newQuestionForm.markAsTouched();
  }
  observationText(e) {
    if (e.text) {
      e.text= e.text.substring(0, e.text.length - 1);
      this.spaceflagObservation= e.text.startsWith(' ') || 
      e.text.endsWith(' ')||e.text.startsWith('\t') || 
      e.text.endsWith('\t');
      let pattern =this.pattern1;
      this.patternFlagObservation= !pattern.test(e.text);
      //e.text = e.text.trim();
      this.observationLength = e.text.length;
      if (e.text.length > 0) {
        this.observation = e.text;
        if (this.observationLength > 2000) {
          // this.observationLength = 2000;
          this.observationLimitExceeded = true;
        }
        else {
          this.observationLimitExceeded = false;
        }
        this.isObservationRequired = false;
      } else {
        this.newQuestionForm.controls['observationJson'].setValue("");
        this.isObservationRequired = true;
      }
      if(this.spaceflagObservation || this.patternFlagObservation)
        {
        this.isObservationRequired = false;
        return;
        }
    } else {
      if(this.observationLength===0)      
      this.isObservationRequired = true;
    }
    if(e.event == 'selection-change')
      this.newQuestionForm.markAsTouched();
  }

  recommendationText(e) {
    if (e.text) {
      e.text= e.text.substring(0, e.text.length - 1);
      this.spaceflagRecommendation= e.text.startsWith(' ') || 
      e.text.endsWith(' ')||e.text.startsWith('\t') || 
      e.text.endsWith('\t');
      let pattern =this.pattern1;
      this.patternFlagRecommendation= !pattern.test(e.text);
      
      //e.text = e.text.trim();
      this.recommendationLength = e.text.length;
      if (e.text.length > 0) {
        this.recommendation = e.text;
        if (this.recommendationLength > 2000) {
          //  this.recommendationLength = 2000
          this.recommendationLimitExceeded = true;
        }
        else {
          this.recommendationLimitExceeded = false;
        }
        this.isRecommendationRequired = false;
      } else {
        this.newQuestionForm.controls['recommendationJson'].setValue("");
        this.isRecommendationRequired = true;
      }
      if(this.spaceflagRecommendation || this.patternFlagRecommendation)
        {
          this.isRecommendationRequired = false;
        return;
      }
    }
    else {
      if(this.recommendationLength===0)      
      this.isRecommendationRequired = true;
    }
    if(e.event == 'selection-change')
      this.newQuestionForm.markAsTouched();
  }
  /**
   * Control Validators
   * @controlName {string}
   * @return {boolean}
   * */
  validator(controlName: string) {
    return this.newQuestionForm?.controls[controlName].invalid
      &&
      this.newQuestionForm.controls[controlName].dirty
      &&
      this.newQuestionForm.controls[controlName].hasError('required')
  }

  saveQuestionMetadata(formValues: any) {     
    // if (this.id !== undefined) {
    if (!this.addQuestionMode) {
     // formValues.enableExecutiveSummary = null;
      this.questionService.updateQuestion(formValues).subscribe(
        () => {
          this.questionMetadatService.getQuestionBuilderList().subscribe(res => {
            this.questionMetadatService.questionBuilders.next(res);
          })
          this.motifmodal.getModalById('createQuestionComponent')?.close(true);

          this.toastr.success(Labels.QuestionUpdatedSuccessfull)
        },
        (error: any) => {        
          if(error.includes("validation errors"))
            this.toastr.error(Labels.ValidationError)
          else   
          this.toastr.error(Labels.ServerErrorOnUpdate); 
        }         
      );
    } else {
      this.questionService.saveQuestion(formValues).subscribe(
        () => {
          this.questionMetadatService.getQuestionBuilderList().subscribe(res => {        

            this.questionMetadatService.questionBuilders.next(res);
          })
          this.motifmodal.getModalById('createQuestionComponent')?.close(true);
          this.toastr.success(Labels.QuestionAddesSuccesfullToFA + ' ' + this.functionalAreaName)
        },
        (error: any) => {        
          if(error.includes("validation errors"))
            this.toastr.error(Labels.ValidationError)
          else   
          this.toastr.error(Labels.ModalCreateQuestionError); 
        }      
      );
    }
  }

  saveQuestionMaster(formValues: any) {   
    if (!this.addQuestionMode) {
      formValues.functionalAreaMaster = formValues.functionalArea;
      this.questionMasterService.updateQuestion(formValues).subscribe(
        (data: any) => {
          this.motifmodal.getModalById('createQuestionComponent')?.close(true);
          this.toastr.success(Labels.QuestionUpdatedSuccessfull)
        },
        (error: any) => {
          if(error.includes("validation errors"))
            this.toastr.error(Labels.ValidationError)
          else   
          this.toastr.error(Labels.ModalCreateQuestionError);
        }
      );
    } else {
      formValues.functionalAreaMaster = formValues.functionalArea;
      this.questionMasterService.saveQuestion(formValues).subscribe(
        () => {
          this.motifmodal.getModalById('createQuestionComponent')?.close(true);

          this.toastr.success(Labels.QuestionAddedSuccesfullToLibrary)
        },
        (error: any) => {        
          if(error.includes("validation errors"))
            this.toastr.error(Labels.ValidationError)
          else   
          this.toastr.error(Labels.ModalCreateQuestionError); 
        } 
      );
    }
  }

  onMultiselectChange(event, form, field) {
    if (field === 'origincountry') {
      form.controls.originCountry.markAsTouched();
      form.controls.originCountry.markAsDirty();
      this.newQuestion.originCountry = event.value.map(s => s.id).join(',');
    } else if (field === 'industry') {
      form.controls.industry.markAsTouched();
      form.controls.originCountry.markAsDirty();
      this.newQuestion.industry = event.value.map(s => s.id).join(',');
    } else if (field === 'exchange') {
      form.controls.exchange.markAsTouched();
      form.controls.originCountry.markAsDirty();
      this.newQuestion.exchange = event.value.map(s => s.id).join(',');
    } else if (field === 'ebenchmarking') {
      form.controls.ebenchmarking.markAsTouched();
      form.controls.ebenchmarking.markAsDirty();
      this.disableUpdateQuestionButton = false;
    }
  }

  DisplayMessage(message: string, type: string) {
    this.alerts.push({
      type: type,
      msg: message
    });
  }
}
