<ng-container *ngIf="newQuestionForm">
  <motif-modal-title class="questiontitle">
    <h6>{{title}}</h6>
    <button class="close-btn" motifIconButton aria-label="Close modal" motif-modal-close>
      <motif-icon class="motif-modal-header-close-icon"
        [src]="'/assets/icons/navigation/ic_close_24px.svg'"></motif-icon>
    </button>
  </motif-modal-title>
  <motif-modal-content class="questioncontent">
    <div class="disclaimer" *ngIf="!addQuestionMode">
      Please avoid changing the substance of the question. If necessary, add a new question.
    </div>
    <form [formGroup]="newQuestionForm" class="questionform">
      <motif-form-field>
        <label motifLabel>Add to functional area</label>
        <motif-select ngDefaultControl [filter]="true" [sortComparator]="true" [disabled]="!addQuestionMode" (change)="changeInFuncArea($event)"
          formControlName="functionalArea" aria-label="Aria Label">
          <motif-option [value]="item" *ngFor="let item of functionalArea">{{item.name}}</motif-option>
        </motif-select>
        <motif-error *ngIf="validator('functionalArea')">
          Required field
        </motif-error>
      </motif-form-field>
      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <motif-form-field>
            <label motifLabel>Country of Origin (The country in which the Company is domiciled)</label>
            <motif-select [filter]="true" [showSelectAllButton]="true" [sortComparator]="true" (change)="changeInCountry($event)"
              [multiple]="true" ngDefaultControl  formControlName="originCountry" aria-label="Aria Label">
              <motif-option [value]="item.id" *ngFor="let item of country">{{item.name}}</motif-option>
            </motif-select>
            <ng-container *ngIf="validator('originCountry')">
            <motif-error>
              Required field
            </motif-error>
            </ng-container>
          </motif-form-field>
        </div>     
        <div class="col-sm-12 col-lg-6">
          <motif-form-field>
            <label motifLabel>Destination (The country in which the Company is to be listed)</label>
            <motif-select [filter]="true" [showSelectAllButton]="true" [sortComparator]="true" (change)="changeInDestination($event)"
              [multiple]="true" ngDefaultControl  formControlName="exchange" aria-label="Aria Label">
              <motif-option [value]="item.id" *ngFor="let item of exchanges">{{item.name}}</motif-option>
            </motif-select>
            <ng-container *ngIf="validator('exchange')">
            <motif-error>
              Required field
            </motif-error>
            </ng-container>
          </motif-form-field>
        </div>
      </div>
      <div class="row field-row">
        <div class="col-sm-12 col-lg-6">
          <motif-form-field>
            <label motifLabel>Industry</label>
            <motif-select ngDefaultControl [filter]="true" [showSelectAllButton]="true" (change)="changeInIndustry($event)" [multiple]="true"
              formControlName="industry" aria-label="Aria Label">
              <motif-option [value]="item.id" *ngFor="let item of industries">{{item.name}}</motif-option>
            </motif-select>
            <ng-container *ngIf="validator('industry')">
            <motif-error>
              Required field
            </motif-error>
            </ng-container>
          </motif-form-field>
        </div>
        <div class="col-sm-12 col-lg-6" *ngIf="!isQuestionMasterMode">
          <motif-form-field>
            <label motifLabel>Criticality</label>
            <motif-select ngDefaultControl [sortComparator]="true" (change)="changeInCriticality($event)" formControlName="weightage"
              aria-label="Aria Label">
              <motif-option [value]="item" *ngFor="let item of weightingList">{{item}}</motif-option>
            </motif-select>
            <motif-error *ngIf="validator('weightage')">
              Required field
            </motif-error>
          </motif-form-field>
        </div>
      </div>
      <motif-form-field class="rich-text-editor-form-field">
        <h2>Question</h2>
        <motif-rich-text-editor format="json" (onEditorChanged)="questionText($event)" id="question"
          placeholder="Question" class="text-editor" ngDefaultControl formControlName="descriptionJson" [modules]="{
          toolbar: [['bold', 'italic', 'underline', { 'list': 'ordered'}, { 'list': 'bullet' }]]
      }">
        </motif-rich-text-editor>
        <span [ngClass]="{'error':questionLimitExceeded}">{{ questionLength }}/500</span>
        <div class="error-container">
          <motif-error *ngIf="isDescriptionRequired">
            Required field
          </motif-error>
          <motif-error *ngIf="questionLimitExceeded">
            Maximum character limit exceeded
          </motif-error>
          <motif-error *ngIf="spaceflagQuestion">
            {{spaceMsg}}
          </motif-error>
          <motif-error *ngIf="patternFlagQuestion">
            {{patternMsg}}
          </motif-error>
        </div>
      </motif-form-field>
      <motif-form-field class="rich-text-editor-form-field">
        <h2>Observation</h2>
        <motif-rich-text-editor format="json" (onEditorChanged)="observationText($event)" placeholder="Observation"
          class="text-editor" formControlName="observationJson" ngDefaultControl [modules]="{
          toolbar: [['bold', 'italic', 'underline', { 'list': 'ordered'}, { 'list': 'bullet' },{ 'color': [] }]]
      }">
        </motif-rich-text-editor>
        <span [ngClass]="{'error':observationLimitExceeded}">{{ observationLength }}/2000</span>
        <div class="error-container">
          <motif-error *ngIf="isObservationRequired">
            Required field
          </motif-error>
          <motif-error *ngIf="observationLimitExceeded">
            Maximum character limit exceeded
          </motif-error>
          <motif-error *ngIf="spaceflagObservation">
            {{spaceMsg}}
          </motif-error>
          <motif-error *ngIf="patternFlagObservation">
            {{patternMsg}}
          </motif-error>
        </div>
      </motif-form-field>
      <motif-form-field class="rich-text-editor-form-field">
        <h2>Recommendation</h2>
        <motif-rich-text-editor format="json" (onEditorChanged)="recommendationText($event)"
          placeholder="Recommendation" class="text-editor" formControlName="recommendationJson" ngDefaultControl
          [modules]="{
          toolbar: [['bold', 'italic', 'underline', { 'list': 'ordered'}, { 'list': 'bullet' },{ 'color': [] }]]
      }">
        </motif-rich-text-editor>
        <span [ngClass]="{'error':recommendationLimitExceeded}">{{ recommendationLength }}/2000</span>
        <div class="error-container">
          <motif-error *ngIf="isRecommendationRequired">
            Required field
          </motif-error>
          <motif-error *ngIf="recommendationLimitExceeded">
            Maximum character limit exceeded
          </motif-error>
          <motif-error *ngIf="spaceflagRecommendation">
            {{spaceMsg}}
          </motif-error>
          <motif-error *ngIf="patternFlagRecommendation">
            {{patternMsg}}
          </motif-error>
        </div>
      </motif-form-field>
    </form>
  </motif-modal-content>
  <motif-modal-actions class="questionaction">
    <button class="confirm"
      [disabled]="newQuestionForm?.invalid  || !newQuestionForm.touched || isDescriptionRequired || isObservationRequired || isRecommendationRequired || questionLimitExceeded || observationLimitExceeded || recommendationLimitExceeded||patternFlagQuestion||spaceflagQuestion||spaceflagObservation||patternFlagObservation||spaceflagRecommendation||patternFlagRecommendation"
      motifButton (click)="saveQuestion()" color="primary-alt">{{saveBtnName}}</button>
    <button class="cancel" motifButton color="secondary" [motif-modal-close]="true">Cancel</button>
  </motif-modal-actions>
</ng-container>