<motif-modal-title class="privacy-policy-title">
    <h6>{{title}}</h6>
    <button class="close-btn" *ngIf="isReadonly" motifIconButton aria-label="Close modal" motif-modal-close>
        <motif-icon class="motif-modal-header-close-icon"
          [src]="'/assets/icons/navigation/ic_close_24px.svg'"></motif-icon>
    </button>
</motif-modal-title>
<motif-modal-content class="privacy-policy-content">
    <div class="motif-row privacy-content">
        <div class="col-lg-12 col-sm-12">
            <p class="privacy-header"> Last updated: January 2024</p>
            <p class="privacy-header-center"> Privacy Notice - IPO Enabler</p>
            <p class="privacy-header">1.&nbsp;&nbsp;Introduction</p>
            <p>This Privacy Notice is intended to describe the practices EY follows in relation to the IPO Enabler (“Tool”)
                with respect to the privacy of all individuals whose personal data
                is processed and stored in the Tool. This Privacy Notice should be read together with the <a
                  href="https://www.ey.com/en_gl/privacy-statement" target="_blank">ey.com Privacy Statement</a>, and in case of
                any conflict with
                the <a href="https://www.ey.com/en_gl/privacy-statement" target="_blank">ey.com Privacy Statement</a>, the terms
                of this Privacy
                Notice will prevail.&nbsp; Please read this Privacy Notice carefully.&nbsp;</p>
            <p class="privacy-header">2.&nbsp;&nbsp;Who manages the Tool?</p>
            <p>&ldquo;EY&rdquo; refers to one or more of the member firms of  <strong> Ernst &amp; Young Global Limited
                (&ldquo;EYG&rdquo;) </strong> each of which is a separate legal entity and can determine the purposes and means for data
                processing in its own right (i.e. act as a data controller or in a similar capacity).</p>
                <p>
                The entity that is acting as
                data controller (or similar capacity) by providing this Tool on which your personal data will be processed and
                stored is:</p>
                <ul class="bullet-class">
                  <li class="circle">For the personal data of EY personnel: The data controller is the EY entity which employs you. 
                  </li>
                  <li class="circle">For the personal data of third-party personnel (including EY clients): 
                    The data controller is the EY local member firm with which the third party has a relationship.</li>
                </ul>
              <p>You can find a list of local EY member firms and affiliates on the <a href="https://www.ey.com/en_gl/privacy-statement" target="_blank">ey.com Privacy Statement</a>.  </p>
              <p>The personal data in the Tool is shared by the above data controller with one or more member firms of EYG (see &ldquo;Who can
                access your personal data&rdquo; section 6 below).</p>
              <p>The Tool is hosted externally on servers in an EY Managed MS Azure Data Centre.</p>
            <p class="privacy-header">3.&nbsp;&nbsp;How does the Tool process personal data?</p>
            <p>Your personal data processed in the Tool is used as follows:</p>
            <p>For the Readiness Assessment component, the following types of data will be processed:</p>
            <p>A client user will answer questions in the Readiness Assessment component, which are designed to assess how 
                ready a company is for an IPO. After completion, these responses are collated and a report is generated
                 regarding how ready the company is for an IPO. Technology upgrade (Upgrading Dot Net 3.1 to Dot Net 6.0 and
                  Angular 6 to Angular 14 with Motif 5) and SSO implementation.</p>
            <p>EY relies on the following basis to legitimize the processing of your personal data in the Tool:</p>
            <p>Processing of your personal data is necessary for the purposes of the legitimate interests pursued by the data controller 
                or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of 
                the data subject which require protection of personal data. The specific legitimate interest(s) are Conducting client 
                engagements.</p>
            <p>You have the right to object at any time, on grounds relating to your particular situation, to the processing of
                 personal data concerning you based on the above legitimate interest(s).</p>
            <p class="privacy-header">4.&nbsp;&nbsp;What type of personal data is processed in the Tool?</p>
            <p>The Tool processes these personal data categories:</p>
            <p>The names of the EY employees, and their login details
                 such as username and password. The questionnaire will also ask questions relating to the below topics.</p>
            <ul class="bullet-class">
                <li class="circle">Capital Markets Strategy</li>
                <li class="circle">Corporate governance/legal</li>
                <li class="circle">Finance and accounting</li>
                <li class="circle">Financial planning & analysis</li>
                <li class="circle">Internal audit</li>
                <li class="circle">Investor relations</li>
                <li class="circle">Information technology</li>
                <li class="circle">Human Capital</li>
            </ul>
            <p>This data is sourced directly from clients and a feed from other EY system i.e., Active directory.</p>
            <p class="privacy-header">5.&nbsp;&nbsp;Sensitive personal data</p>
            <p>
                Sensitive personal data reveals your racial or ethnic origin, political opinions,
                 religious or philosophical beliefs, trade union membership, genetic data, biometric data,
                  data concerning health or data concerning sex life or sexual orientation.
            </p>
            <p>
                EY does not intentionally collect any sensitive personal data from you via the Tool. 
                The Tool's intention is not to process such information.”
            </p>
            <p class="privacy-header">6.&nbsp;&nbsp;Who can access your personal data?</p>
            <p>Your personal data is accessed in the Tool by the following persons/teams:</p>
            <ul class="bullet-class">
                <li class="circle">Access within EY</li>
                <li class="circle">The EY IPO FAAS Team and Senior stakeholders are the only EY personnel who
                     will be able to access the platform.</li>
            </ul>
            <table class="privacytable">
                <tbody>
                  <tr>
                    <td class="privacy-table" width="93">
                      <p><strong>USER GROUP</strong></p>
                    </td>
                    <td class="privacy-table" width="103">
                      <p><strong>LOCATION</strong></p>
                    </td>
                    <td class="privacy-table" width="101">
                      <p><strong>PURPOSE</strong></p>
                    </td>
                    <td class="privacy-table" width="96">
                      <p><strong>ACCESS</strong></p>
                    </td>
                    <td class="privacy-table" width="75">
                      <p><strong>AMOUNT</strong></p>
                    </td>
                  </tr>
                  <tr>
                    <td width="93">
                      <p><strong>Group name</strong></p>
                    </td>
                    <td width="103">
                      <p><strong>Identify country</strong></p>
                    </td>
                    <td width="101">
                      <p><strong>The action that the group performs</strong></p>
                    </td>
                    <td width="96">
                      <p><strong>Identify whether access is read, update, add</strong></p>
                    </td>
                    <td width="75">
                      <p><strong>Provide the approx. number of users with access</strong></p>
                    </td>
                  </tr>
                  <tr>
                    <td width="93">
                      <p>Super Admin</p>
                    </td>
                    <td width="103">
                      <p>Global</p>
                    </td>
                    <td width="101">
                      <p>- Provide access to other EY Personnel - Add, delete, read users for different
                         engagements - Create new client engagements. - Can customize questions for assessments. - Debugging</p>
                    </td>
                    <td width="96">
                      <p>- Add, edit, delete clients at the product level - Add, delete users for different 
                        engagements - Read log data for debugging and infrastructure logs (Note that such logs are
                         relating to defects of the tool and do not contain personal data users)</p>
                    </td>
                    <td width="75">
                      <p>3 - 5 Globally</p>
                    </td>
                  </tr>
                  <tr>
                    <td width="93">
                      <p>Super Admin (Third-party vendor employees (TCS) who support the tool)</p>
                    </td>
                    <td width="103">
                      <p>Global</p>
                    </td>
                    <td width="101">
                      <p>- Provide access to other EY Personnel - Add, delete, read users for different
                         engagements - Create new client engagements. - Can customize questions for 
                         assessments. - Debugging</p>
                    </td>
                    <td width="96">
                      <p>- Add, edit, delete clients at the product level - Add, delete users for different
                         engagements - Read log data for debugging and infrastructure logs (Note that such logs
                          are relating to defects of the tool and do not contain personal data users)</p>
                    </td>
                    <td width="75">
                      <p>1 - 2 Globally</p>
                    </td>
                  </tr>
                  <tr>
                    <td width="93">
                      <p>Admin</p>
                    </td>
                    <td width="103">
                      <p>Global</p>
                    </td>
                    <td width="101">
                      <p>- Access, modify, delete assessments - Add, read users under specific
                         engagement - Input basic client details - Provide access to other EY Personnel
                          (specifically other Admin or EY Practitioner)</p>
                    </td>
                    <td width="96">
                      <p>Add/delete assessments under specific engagements Add users under specific engagement</p>
                    </td>
                    <td width="75">
                      <p>1-2 per engagement</p>
                    </td>
                  </tr>
                  <tr>
                    <td width="93">
                      <p>EY Practitioner (General user)</p>
                    </td>
                    <td width="103">
                      <p>Global</p>
                    </td>
                    <td width="101">
                      <p>- Access and complete assessments</p>
                    </td>
                    <td width="96">
                      <p>Edit assessments under engagements they have been granted access to</p>
                    </td>
                    <td width="75">
                      <p>2-3 per engagement</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>The access rights detailed above involves transferring personal data in various jurisdictions (including
                jurisdictions outside the European Union) in which EY operates (EY office locations are listed at <a
                  href="https://www.ey.com/en_gl/locations" target="_blank">https://www.ey.com/en_gl/locations</a>). An overview of EY network
                entities providing
                services to external clients is accessible <a href="https://www.ey.com/en_uk/legal-statement"
                  target="_blank">here</a> (See
                Section 2 (About EY) - &ldquo;View a list of EY member firms and affiliates&rdquo;). EY will process your personal
                data in the Tool in accordance with applicable law and professional regulations in your jurisdiction. Transfers of
                personal data within the EY network are governed by EY&rsquo;s <a
                  href="https://www.ey.com/en_gl/data-protection-binding-corporate-rules-program" target="_blank">Binding
                  Corporate Rules</a>.</p>
            <p>
                We transfer or disclose the personal data we collect to third-party service providers
                 (and their subsidiaries and affiliates) who are engaged by us to support our internal
                  ancillary processes. For example, we engage service providers to provide, run and support
                   our IT infrastructure (such as identity management, hosting, data analysis, back-up, security 
                   and cloud storage services) and for the storage and secure disposal of our hard copy files.
                It is our policy to only use third-party service providers that are bound to maintain appropriate levels
                 of data protection, security and confidentiality, and that comply with any applicable legal requirements
                  for transferring personal data outside the jurisdiction in which it was originally collected.
            </p>
            <p>
                To the extent that personal data has been rendered anonymous in such a way that you or your device are no
                 longer reasonably identifiable, such information will be treated as non-personal data and the terms of this
                  Privacy Notice will not apply.
            </p>
            <p class="privacy-header">7.&nbsp;&nbsp;Data retention</p>
            <p>Our policy is to retain personal data only for as long as it is needed for the purposes described in the section
                “How does the Tool process personal data”. Retention periods vary in different jurisdictions and are set in
                accordance with local regulatory and professional retention requirements.</p>
              <p>In order to meet our professional and legal requirements, to establish, exercise or defend our legal rights and
                for archiving and historical purposes, we need to retain information for significant periods of time.</p>
              <p>The policies and/or procedures for the retention of personal data in the Tool are:</p>
              <p>The total retention period is defined and will be implemented in accordance with the
                <a
                  href="https://find.ey.net/discover/sitepages/home.aspx?detailPath=ZYFCSFS674FF-1228837741-3334#detailPath=ZYFCSFS674FF-1228837741-3334" target="_blank">EY Records Retention Global Policy</a> 
                and the relevant <a href="https://ig2.ironmountainconnect.com/ey/p/grs/section/country-retention-schedules-6398e5eae34d8" target="_blank"> Retention Schedule (CRS).</a></p> 
                <p>
                Log Data will be retained in accordance with the <a
                  href="https://sites.ey.com/sites/informationsecurity/Policy%20Library/Logging_Policy.pdf" target="_blank">EY IT
                  Logging
                  Policy</a>.</p>
              <p>After the end of the data retention period, your personal data will be deleted.</p>
              <p class="privacy-header">8.&nbsp;&nbsp;Security</p>
              <p>EY protects the confidentiality and security of information it obtains in the course of its business. Access to
                such information is limited, and policies and procedures are in place that are designed to safeguard the
                information from loss, misuse and improper disclosure. Additional information regarding our approach to data
                protection and information security is available in our <a
                  href="https://assets.ey.com/content/dam/ey-sites/ey-com/en_gl/topics/consulting/ey-protecting-your-data-brochure-v2.pdf"
                  target="_blank">ey-protecting-your-data-brochure-v2.pdf</a> brochure.</p>
             <p class="privacy-header">9.&nbsp;&nbsp;Controlling your personal data</p>
             <p>EY will not transfer your personal data to third parties (other than any external parties referred to in
                 section 6 above) unless we have your permission or are required by law to do so.</p>
             <p class="privacy-header">10.&nbsp;&nbsp;Your rights in relation to your personal data</p>
             <p>Depending on the applicable jurisdiction, you may have certain rights in relation to your personal data, including:</p>
             <ul class="bullet-class">
                <li class="circle">To request details of the personal data EY processes about you and to access the personal data that EY processes 
                  about you
                </li>
                <li class="circle">To have your personal data corrected, for example, if it is incomplete or incorrect</li>
                <li class="circle">To restrict or object to the processing of personal data or request the erasure of your personal data 
                </li>
                <li class="circle">To receive a copy of the personal data which you have provided to EY in a structured, 
                  commonly used and machine-readable format which you can re-use for your own purposes (known as “data portability”)
                </li>
                <li class="circle">Where you have provided consent to the processing of your personal data, the right to withdraw your consent. 
                </li>
                <li class="circle">The right to complain to a data protection authority (see section “Complaints”) 
                </li>
              </ul>
              <p>If you have any questions about how EY processes your personal data or your rights related to your personal data,
                 please send an e-mail to global.data.protection@ey.com.</p>
              <p class="privacy-header">11.&nbsp;&nbsp;Complaints</p>
              <p>If you are concerned about an alleged breach of privacy law or any other regulation, contact EY&rsquo;s Global
                Privacy Leader, Office of the General Counsel, 1 More London Place, London, SE1 2AF, United Kingdom or via email
                at <a href="mailto:global.data.protection@ey.com" target="_blank">global.data.protection@ey.com</a> or via your
                usual EY
                representative. An EY Privacy Leader will investigate your complaint and provide information about how it will be
                handled and resolved.</p>
              <p>If you are not satisfied with how EY resolved your complaint, you have the right to complain to your
                country&rsquo;s data protection authority. You may also have the right to refer the matter to a court of competent jurisdiction.
                &nbsp;</p>
              <p>Certain EY member firms in countries outside the European Union (EU) and the UK have appointed representatives in
                 the EU and the UK respectively to act on their behalf if, and when, they undertake data processing activities to which
                  the EU General Data Protection Regulation (GDPR) and/or the UK General Data Protection Regulation (UK GDPR) applies. 
                  Further information and the contact details of these representatives are available below: </p>
                  <p> <a
                    href="https://www.ey.com/en_gl/data-protection-representative" target="_blank">EU data protection representative | EY
                  </a></p>
                  <p> <a
                    href="https://www.ey.com/en_rs/uk-data-protection-representative" target="_blank">UK Data protection representative (ey.com)
                  </a></p>
                <p class="privacy-header">12.&nbsp;&nbsp;Contact us</p>
                <p>If you have additional questions or concerns, contact your usual EY representative or email <a
                    href="mailto:global.data.protection@ey.com" target="_blank">global.data.protection@ey.com</a>.</p>
                <p>&nbsp;</p>
        </div>
    </div>
    <form [formGroup]="privacyPolicyForm" class="privacy-policy-form">
        <div class="row">
            <motif-form-field *ngIf="!isReadonly">
                <motif-checkbox name="policy" formControlName="policy" class="privacy-policy-checkbox checkbox" [(ngModel)]="checked" (change)="onChange($event)">I acknowledge that I have read the privacy notice.</motif-checkbox>
            </motif-form-field>
        </div>
    </form>
</motif-modal-content>
<motif-modal-actions class="privacy-policy-action" [ngClass]="isReadonly ? 'justify-content-left' : 'justify-content-right' ">
    <button *ngIf="!isReadonly"
    [disabled]="!checked"
    motifButton (click)="acceptPolicy()" color="primary-alt" [motif-modal-close]="true">{{saveBtnName}}</button>
    <button *ngIf="isReadonly" motifButton color="secondary" [motif-modal-close]="true">Close</button>
</motif-modal-actions>