import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Notes } from 'src/app/models/report.model';
import { ModalSizes, MotifModal, MotifModalRef } from '@ey-xd/ng-motif';
import { CreateNotesComponent } from '../../create-notes/create-notes.component';
import { Labels } from 'src/app/locale/en/labels.en';
import { ConfirmComponent } from 'src/app/shared/confirm/confirm.component';
import { NotesService } from 'src/app/_services/notes.service';
import { ToasterService } from 'src/app/shared/toast/toaster.service';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent implements OnInit, ICellRendererAngularComp {
  cellValue;
  eGui;
  public motifModalRef?: MotifModalRef<any>;
  @ViewChild('actionPerform') actionPerform: TemplateRef<any>;
  delNote: Notes;
  notes: Notes[];
  constructor(public motifmodal?:MotifModal,public notesService?: NotesService,private toastr?: ToasterService) { }

  ngOnInit(): void {
    this.eGui = this.actionPerform;
  }

  getGui() {
    return this.eGui;
  }
  agInit(params) {
    this.cellValue = params.value;
  }
  refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.cellValue = params.value;
    return true;
  }

  openModalPopUp(guid:any, actionType:string): void
  {
    if (guid !== '') {
      this.notesService.getNote(guid).subscribe(
        note => {
          this.delNote = note;
        },
        error => {
          this.toastr.error(Labels.ServerErrorOnLoad);
        }
      );
    }
    switch(actionType) {
      case "edit":  
         {
          this.motifModalRef = this.motifmodal.open(CreateNotesComponent, {
            id: "updateNotes",
            data: {
              initialState: {
                title: Labels.ModalTitleUpdateNote,
                guid: guid,
                closeBtnName: Labels.ModalDeleteCancelBtnName,
                saveBtnName: Labels.ModalUpdateBtnUpdate
              },
              backdrop: 'static',
              keyboard: false,
            },
            size: ModalSizes.XL,
          });

          this.motifModalRef.addPanelClass('addNotesPopUp');
          this.motifModalRef.afterClosed().subscribe(result => {
            if (result){
              this.notesService.getNotes().subscribe(
                notes => {
                  this.notes = notes;
                  this.notesService.notes.next(this.notes);
                },
                error => {
                  this.toastr.error(Labels.ServerError);
                }
              );
            }
          });
           break;
         }  
      case "delete":  
         {  
          this.motifModalRef = this.motifmodal.open(ConfirmComponent, {
            id:"deleteNotes",
            data: {
            initialState: {
              title: Labels.ModalTitleDeleteNote,
              content: Labels.ModalContentDeleteNote,
              confirmBtnName: Labels.ModalDeleteConfirmBtnName,
              cancelBtnName: Labels.ModalDeleteCancelBtnName,
              isDeleteButton: true
                },
            backdrop: 'static',
            keyboard: false,
            }
          }); 

          this.motifModalRef.afterClosed().subscribe(result => {
            if (result){
             this.loadNotes();
             this.DeleteNote(this.delNote);
            }
          });
           break; 
         }  
     }
   
  }

  DeleteNote(note: Notes) {
    this.notesService.deleteNote(note).subscribe(
      () => {
        const findIndex = this.notes.findIndex(a => a.guid === note.guid)
        this.notes.splice(findIndex, 1);
        this.notesService.notes.next(this.notes);
        this.toastr.success(Labels.ModalDeleteNoteSuccess);
        this.delNote = null;
      },
      error => {
        this.toastr.error('An unexepected error while deleting the Note.');
      }
    );
  }

  loadNotes() {
    this.notesService.getNotes().subscribe(
      notes => {
        this.notes = notes;
      },
      error => {
        this.toastr.error(Labels.ServerError);
      }
    );
  }
}

