import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { QuestionMetadata, FunctionalArea } from './questionMetadata';
import { SettingsProvider } from 'src/app/settingsProvider';
import { FunctionalAreaMaster } from 'src/app/models/functionalareamaster.model';
import { environment } from 'src/assets/client-config';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  private questionApiUrl = environment.apiUrl + '/api/QuestionMetadata/';
  private functionalAreaApiUrl = environment.apiUrl + '/api/FunctionalArea/';

  constructor(private http: HttpClient, private settings: SettingsProvider) { }

  getFunctionalAreaList(): Observable<FunctionalArea[]> {
    return this.http
      .get<FunctionalArea[]>(this.functionalAreaApiUrl)
      .pipe(tap((data) => data));
  }

  saveFunctionalArea(functionalArea) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<QuestionMetadata>(this.functionalAreaApiUrl, functionalArea, options);
    // .pipe(
    //   catchError(this.handleError<QuestionMetadata>('saveFunctionalArea'))
    // );
  }

  saveQuestion(question) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post<QuestionMetadata>(
        this.questionApiUrl + 'SaveQuestionWithMetadata',
        question,
        options
      )
      .pipe(tap(data => data));
      //.pipe(catchError(this.handleError<QuestionMetadata>('saveQuestion')));
  }

  getQuestion(id): Observable<QuestionMetadata> {
    return this.http
      .get<QuestionMetadata>(this.questionApiUrl + 'GetQuestion/' + id)
      .pipe(tap((data) => data));
  }

  updateQuestion(question) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.put<QuestionMetadata>(this.questionApiUrl + 'UpdateQuestionWithMetadata', question, options);
    // .pipe(
    //   catchError(
    //     this.handleError<QuestionMetadata>('UpdateQuestionWithMetadata')
    //   )
    // );
  }
  updateFAName(funcArea) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.put<FunctionalAreaMaster>(this.questionApiUrl + 'UpdateFAWithMetadata', funcArea, options);
    // .pipe(
    //   catchError(
    //     this.handleError<QuestionMetadata>('UpdateQuestionWithMetadata')
    //   )
    // );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }
}
