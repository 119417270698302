import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Users, User, UserEngagementEntitlement, AdUser } from '../models/user.model';
import { tap, catchError } from 'rxjs/operators';
import { SettingsProvider } from '../settingsProvider';
import { environment } from 'src/assets/client-config';

@Injectable({
  providedIn: 'root'
})
export class UsermanagementService {
  private userApiUrl = environment.apiUrl + '/api/Users/';
  public profilePic = new BehaviorSubject<any | null>(null);

  constructor(private http: HttpClient, private settings: SettingsProvider) { }

  getUsers(): Observable<Users[]> {
    return this.http
      .get<Users[]>(this.userApiUrl + 'GetUsers')
      .pipe(tap((data) => data), catchError(this.handleError<Users[]>('getUsers')));
  }

  searchUser(searchTerm: string): Observable<AdUser[]> {
    return this.http
      .get<AdUser[]>(this.userApiUrl + 'SearchUser?searchTerm=' + searchTerm)
      .pipe(tap((data) => data), catchError(this.handleError<AdUser[]>('searchUser')));
  }

  getUserRoles(id): Observable<User> {
    return this.http
      .get<User>(this.userApiUrl + 'GetUserRoles/' + id)
      .pipe(tap((data) => data), catchError(this.handleError<User>('getNote')));
  }

  getUserRolesEntitlement(): Observable<UserEngagementEntitlement> {
    return this.http
      .get<UserEngagementEntitlement>(this.userApiUrl + 'GetUserRolesEntitlement')
      .pipe(tap((data) => data), catchError(this.handleError<UserEngagementEntitlement>('getNote')));
  }

  getRoles(): Observable<any[]> {
    return this.http
      .get<any[]>(this.userApiUrl + 'GetRoles')
      .pipe(tap((data) => data), catchError(this.handleError<any[]>('getRoles')));
  }

  getUserImage(id: any): Observable<any[]> {    
    return this.http
      .get<any[]>(this.userApiUrl + 'GetUserImage/' +id)
      .pipe(tap((data) => data), catchError(this.handleError<any[]>('getUserImage')));
  }

  getUserImageProfile(): Observable<any[]> {    
    return this.http
      .get<any[]>(this.userApiUrl + 'GetUserImage')
      .pipe(tap((data) => data), catchError(this.handleError<any[]>('getUserImageProfile')));
  }

  getWorkspace(): Observable<any[]> {
    return this.http
      .get<any[]>(this.userApiUrl + 'GetDdlWorkspace')
      .pipe(tap((data) => data), catchError(this.handleError<any[]>('getWorkspace')));
  }

  SaveUserRoles(userRoles) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<User>(this.userApiUrl + 'SaveUserRoles', userRoles, options);
  }

  deleteUserRole(user: Users) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .delete<Users>(this.userApiUrl + user.engagementRoleId, options)
      .pipe(catchError(this.handleError<Users>('deleteUserRole')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }
}
