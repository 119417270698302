import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-checkbox-inscope-renderer',
  templateUrl: './checkbox-inscope-render.component.html',
  styleUrls: ['./checkbox-inscope-render.component.css']
})

export class CheckBoxInScopeRendererComponent implements ICellEditorAngularComp {
  public checked: any; 
  public params: any;
  columnName: string;
  public formGroup:FormArray;

  agInit(params: any): void { 
    this.params = params;
    this.checked = params.value
  } 
 
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  getValue(): any { 
    return this.checked; 
  } 
 
  isPopup(): boolean { 
    return true; 
  } 
  onChange($event) {
    if (this.params.change instanceof Function) {
      let id = this.params.context.functionalAreaId;
      let rowIndex = this.params.rowIndex;
      this.params.context.formGroup.controls[id].controls.questionMetadatas.controls[rowIndex].controls['isInScope'].patchValue($event);
      this.params.setValue($event);

      const params = {
        event: $event,
        data: this.params.data,
        node: this.params.node,
        api: this.params.api,
        control: this.params.context.formGroup.controls[id].controls.questionMetadatas.controls[rowIndex].controls['isInScope']
      }

      params.control.markAsDirty();
      this.params.change(params);
    }
  }
} 