<motif-modal-title>
  <h5>{{title}}</h5>
  <button class="close-btn" motifIconButton aria-label="Close modal" [motif-modal-close]="true">
      <motif-icon class="motif-modal-header-close-icon"
        [src]="'/assets/icons/navigation/ic_close_24px.svg'"></motif-icon>
    </button>
</motif-modal-title>
<motif-modal-content>
  <form [formGroup]="newProjectForm">
      <motif-form-field>
          <label motifLabel class="select-label project-name-label">Project name (For confidentiality purposes, please avoid using the client’s company name as the project name.​)</label>
          <input maxlength="100" formControlName="name" motifInput type="text"
          (input)="spaceValidator('name')"/>
          <motif-error *ngIf="validator('name')">Required field</motif-error>
          <motif-error *ngIf="newProjectForm?.controls.name.hasError('spaceIssue')">{{spaceMsg}}</motif-error>
          <motif-error *ngIf="newProjectForm?.controls.name.hasError('patternIssue')">{{patternMsg}}</motif-error>
        </motif-form-field>
      <div class="motif-row">
          <div class="motif-col-sm-2">
              <motif-form-field>
                  <label motifLabel class="select-label">Client name</label>
                  <input maxlength="100" formControlName="clientName" motifInput type="text" (input)="spaceValidator('clientName')"/>
                  <motif-error *ngIf="validator('clientName')">Required field</motif-error>
                  <motif-error *ngIf="newProjectForm?.controls.clientName.hasError('spaceIssue')">{{spaceMsg}}</motif-error>
                  <motif-error *ngIf="newProjectForm?.controls.clientName.hasError('patternIssue')">{{patternMsg}}</motif-error>        
                  </motif-form-field>
          </div>
          <div class="motif-col-sm-2">
              <motif-form-field>
                  <label motifLabel class="select-label">Industry</label>

                  <motif-select ngDefaultControl formControlName="industryId" aria-label="Aria Label">
                      <motif-option [value]="industry.id" *ngFor="let industry of industry">{{industry.name}}</motif-option>
                  </motif-select>
                  <motif-error *ngIf="validator('industryId')">
                      Required field
                  </motif-error>
              </motif-form-field>
          </div>
      </div>
      <div class="motif-row">
          <div class="motif-col-sm-2">
              <motif-form-field>
                  <label motifLabel class="select-label">Partner name</label>
                  <input class="partner" [pattern]="pattern" (keyup)="loadPartners($event.target.value)" [motifTypeahead]="partners" formControlName="partnerName" motifInput type="text" />
                  <motif-error *ngIf="validator('partnerName')">
                     Required field
                  </motif-error>
                  <motif-inline-message class="error-message" [type]="'error'" *ngIf="newProjectForm.controls.partnerName.hasError('pattern')">
                    Invalid user
                  </motif-inline-message>
              </motif-form-field>
          </div>
          <div class="motif-col-sm-2">
              <motif-form-field>
                  <label motifLabel class="select-label">Senior manager name</label>
                  <input [pattern]="pattern" (keyup)="loadSeniorManagers($event)" formControlName="seniorManagerName" [motifTypeahead]="seniorManagers" motifInput type="text" />
                  <motif-error *ngIf="validator('seniorManagerName')">
                    Required field
                </motif-error>
                <motif-inline-message class="error-message" [type]="'error'" *ngIf="newProjectForm.controls.seniorManagerName.hasError('pattern')">
                    Invalid user
                  </motif-inline-message>
                </motif-form-field>
          </div>
      </div>
      <div class="motif-row">
          <div class="motif-col-sm-2">
              <motif-form-field>
                  <label motifLabel class="select-label">City</label>
                  <input [pattern]="'^[a-zA-Z ]*$'" maxlength="100" formControlName="city" motifInput type="text" (input)="spaceValidatorCity('city')"/>
                  <motif-error *ngIf="validator('city')">Required field</motif-error>
                  <motif-error *ngIf="newProjectForm?.controls.city.hasError('spaceIssue')">{{spaceMsg}}</motif-error>
                  <motif-error *ngIf="newProjectForm?.controls.city.hasError('patternIssue')">Only alphabets allowed</motif-error>        
              </motif-form-field>
          </div>
          <div class="motif-col-sm-2">
              <motif-form-field>
                  <label motifLabel class="select-label">Country</label>

                  <motif-select ngDefaultControl formControlName="countryId" aria-label="Aria Label" >
                      <motif-option [value]="country.id" *ngFor="let country of country">{{country.name}}</motif-option>
                  </motif-select>
                  <motif-error *ngIf="validator('countryId')">
                      Required field
                  </motif-error>
              </motif-form-field>
          </div>
      </div>
  </form>
</motif-modal-content>
<motif-modal-actions>
  <button (click)="saveWorkspace()" [disabled]="newProjectForm?.invalid || !newProjectForm.touched" motifButton color="primary-alt" [motif-modal-close]="true"
       role="button">{{saveBtnName}}</button>
  <button motifButton motif-modal-close color="secondary" aria-label="Cancel" role="button">Cancel</button>
</motif-modal-actions>
