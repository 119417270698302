import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-date-created',
  templateUrl: './date-created.component.html',
  styleUrls: ['./date-created.component.css']
})
export class DateCreatedComponent implements OnInit, ICellRendererAngularComp {
  cellValue;
  eGui;
  date;
  // datePipe: DatePipe = new DatePipe("es-ES");
  @ViewChild('createdOn') name: TemplateRef<any>;
  constructor() { }


  ngOnInit(): void {   
    this.eGui = this.name
  }
  getGui() {
    return this.eGui;
}
  agInit(params) {
    this.cellValue = params.value;
  }
  refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.cellValue = params.value;
    return true;
  }
  getDate(date: string) {   
    return new  Date(date);
  }
}