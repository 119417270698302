import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsProvider } from '../settingsProvider';
import { FunctionalAreaMaster } from '../models/functionalareamaster.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { environment } from 'src/assets/client-config';

@Injectable({
  providedIn: 'root'
})
export class FunctionalareamasterService {
  refreshFunctionalAreaQuestionSetup = new BehaviorSubject<boolean>(false);
  private faMasterApiUrl =
    environment.apiUrl + '/api/FunctionalAreaMaster/';
  // ?FunstionalAreaMasterId=1&OriginCountry=2,3&DestinationExchange=5,6&Industry=7,8';
  constructor(private http: HttpClient, public settings: SettingsProvider) { }

  getFunctionalAreaMaster(): Observable<FunctionalAreaMaster[]> {
    return this.http
      .get<FunctionalAreaMaster[]>(this.faMasterApiUrl)
      .pipe(tap(data => data));
  }

  saveFunctionalAreaMaster(functionalArea) {
    const options = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };
    return this.http.post<FunctionalAreaMaster>(
      this.faMasterApiUrl,
      functionalArea,
      options
    );
  }
}
