import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-button-renderer',
  templateUrl: './select-render.component.html',
  styleUrls: ['./select-render.component.css']
})

export class SelectRendererComponent implements ICellEditorAngularComp {
  public selectedValue: any; 
  public params: any;
  weightageList: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  
  agInit(params: any): void { 
    var str = params.value;
    this.params = params;
    this.selectedValue = str.toString();
  } 
 
  getValue(): any { 
    return this.selectedValue; 
  } 
 
  isPopup(): boolean { 
    return true; 
  } 
  
  onChange($event) {
    if (this.params.change instanceof Function) {
      let id = this.params.context.functionalAreaId;
      let rowIndex = this.params.rowIndex;
      this.params.context.formGroup.controls[id].controls.questionMetadatas.controls[rowIndex].controls['weightage'].patchValue($event);
      this.params.setValue($event);

      const params = {
        event: $event,
        data: this.params.data,
        control: this.params.context.formGroup.controls[id].controls.questionMetadatas.controls[rowIndex].controls['weightage']
      }

      params.control.markAsDirty();

      this.params.change(params);
    }
  }
} 