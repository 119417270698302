<motif-modal-title>
  <h3 [ngClass]="title=='Delete question' || title=='Delete questions' ? 'delete-question' : '' " >
    <motif-icon *ngIf="isDeleteButton" [src]="'/assets/icons/alert/ic_error_24px.svg'"></motif-icon> {{title}} </h3>
  <button class="close-btn" motifIconButton aria-label="Close modal" (click)="confirmCancel()" [motif-modal-close]="false">
    <motif-icon class="motif-modal-header-close-icon" [src]="'/assets/icons/navigation/ic_close_24px.svg'"></motif-icon>
  </button>
</motif-modal-title>
<motif-modal-content>
  <div class="modal-body text-left">

    <span [innerHtml]="content"></span>
  </div>
</motif-modal-content>
<motif-modal-actions>
  <button (click)="closePopup()" motifButton color="primary-alt" [ngClass]="{'delete-button': isDeleteButton}" [motif-modal-close]="true"
    aria-label={{confirmBtnName}} role="button">{{confirmBtnName}}</button>
  <button (click)="confirmCancel()" motifButton motif-modal-close color="secondary" aria-label={{cancelBtnName}}
    role="button">{{cancelBtnName}}</button>
</motif-modal-actions>