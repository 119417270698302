<ng-container>
  <div class="logout">
    <div class="logout-detail">
      <div class="ey-logo">
        <a class="logo" routerLink="/" tabindex="0" aria-label="Home Page">
            <img src="./../../assets/ey-logo-bl.svg" alt="EY" />
        </a>
        <span class="brand-text">IPO Enabler</span>
      </div>
      <img class="logout-image mr-1" src="./../../assets/logout/group.svg" alt="group"/>
      <div class="logout-details">
        <img class="frame frame_start mb-2 ml-2" src="./../../assets/logout/frame.svg" alt="frame"/>
        <div class="logout-content">
          <h2 class="text"> You have successfully logged out.</h2>
          <p class="text">Click here to login again</p>
          <button motifButton color="primary" size="medium"	(click)="login()">Login</button>
        </div>
        <img class="frame frame_end mt-2" src="./../../assets/logout/frame.svg" alt="frame"/>
    </div>
  </div>
  </div>
</ng-container>


