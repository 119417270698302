import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { LicenseManager } from '@ag-grid-enterprise/core';
// import { production, agGridEnterpriseMode, aggridEnterpriseLicenseKey  } from './assets/client-config.json';

const cconfig = require('./assets/client-config.ts')
	if (cconfig.production) {
		enableProdMode();
	}


 platformBrowserDynamic().bootstrapModule(AppModule).catch();

const loadAgGrid = async () => {
	// const clientConfig: Object = await import('./assets/client-config.json');
	// const agGridModule = await import('@ag-grid-enterprise/core');
	// agGridModule.LicenseManager.setLicenseKey(cconfig.aggridEnterpriseLicenseKey);
	LicenseManager.setLicenseKey(cconfig.aggridEnterpriseLicenseKey);
};


	if (cconfig.agGridEnterpriseMode) {
		loadAgGrid();
	}
