import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { FunctionalArea, QuestionBuilder, QuestionMetadata } from '../questionairrelibrary/questionMetadata';
import { QuestionStepper } from '../questionnaireclientview/question-stepper/questionstepper';
import { SettingsProvider } from 'src/app/settingsProvider';
import { environment } from 'src/assets/client-config';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  private questionApiUrl = environment.apiUrl + '/api/QuestionMetadata/';

  constructor(private http: HttpClient, private settings: SettingsProvider) { }

  getStages(){
    return this.http
    .get<any>(environment.apiUrl + '/api/Stage/')
    .pipe(tap((data) => data));
  }

  getWorkDurations(){
    return this.http
    .get<any>(environment.apiUrl + '/api/DurationOfWork')
    .pipe(tap((data) => data));
  }
  getAllInScopeQuestions(): Observable<QuestionBuilder> {
    return this.http
      .get<QuestionBuilder>(this.questionApiUrl + 'GetAllInScopeQuestions/')
      .pipe(tap((data) => data), catchError(this.handleError<QuestionBuilder>('getAllInScopeQuestions')));
  }

  getQuestionByFunctionalAreaAndQuestion(faguid, qnguid): Observable<QuestionBuilder> {
    return this.http
      .get<QuestionBuilder>(
        this.questionApiUrl + 'GetQuestionByFunctionalAreaAndQuestion/' + faguid + '/' + qnguid
      )
      .pipe(
        tap((data) => data),
        catchError(this.handleError<QuestionBuilder>('getQuestionByFunctionalAreaAndQuestion'))
      );
  }

  updateQuestionnaireResponseList(functionalAreaQuestions: QuestionMetadata[]): Observable<FunctionalArea[]> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .put<FunctionalArea[]>(
        this.questionApiUrl + 'UpdateQuestionairreResponse/',
        functionalAreaQuestions,
        options
      )
      .pipe(tap(data =>data));
  }

  updateQuestionDetails(questionMetadata: QuestionMetadata): Observable<QuestionMetadata> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.put<QuestionMetadata>(
      this.questionApiUrl + 'UpdateQuestionDetails/',
      questionMetadata,
      options
    );
  }

  clearQuestionResponse(guid: string): Observable<QuestionMetadata> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.put<QuestionMetadata>(
      this.questionApiUrl + 'ClearQuestionResponse/' + guid,
      null,
      options
    );
  }

  getStepperQuestions(): Observable<QuestionStepper[]> {
    return this.http
      .get<QuestionStepper[]>(this.questionApiUrl + 'GetStepperQuestions')
      .pipe(tap((data) => data));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  addQuestionComment(commentbody): Observable<QuestionMetadata> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<QuestionMetadata>(
      environment.apiUrl + '/api/QuestionComments/AddQuestionComments',
      commentbody,
      options
    );
  }

  updateQuestionComments(commentbody): Observable<QuestionMetadata> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.put<QuestionMetadata>(
      environment.apiUrl + '/api/QuestionComments/UpdateQuestionComments',
      commentbody,
      options
    );
  }

  deleteQuestionComment(qidentifier:any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.delete(
      environment.apiUrl + '/api/QuestionComments/' + qidentifier,
      options
    );
  }

  addQuestionCommentsReply(commentbody): Observable<QuestionMetadata> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<QuestionMetadata>(
      environment.apiUrl + '/api/QuestionCommentsReply/AddQuestionCommentsReply',
      commentbody,
      options
    );
  }

  updateQuestionCommentsReply(commentbody): Observable<QuestionMetadata> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.put<QuestionMetadata>(
      environment.apiUrl + '/api/QuestionCommentsReply/UpdateQuestionCommentsReply',
      commentbody,
      options
    );
  }

  deleteQuestionCommentReply(qidentifier:any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.delete(
      environment.apiUrl + '/api/QuestionCommentsReply/' + qidentifier,
      options
    );
  }
}
