import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-note-template',
  templateUrl: './note-template.component.html',
  styleUrls: ['./note-template.component.css']
})
export class NoteTemplateComponent implements OnInit, ICellRendererAngularComp {
  cellValue;
  eGui;
  @ViewChild('note') note: TemplateRef<any>;
  constructor() { }


  ngOnInit(): void {
    this.eGui = this.note
  }
  getGui() {
    return this.eGui;
}
  agInit(params) {
    this.cellValue = params.value;
  }
  refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.cellValue = params.value;
    return true;
  }
}