import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { WorkspaceService } from '../workspace/workspace.service';

@Injectable({
	providedIn: 'root'
})
export class EngagementGuard implements CanActivate {
	constructor(private workspaceService: WorkspaceService, private router: Router) {}

	canActivate(): boolean {
		let isValidEngagementId = false;
		if (
			this.workspaceService.getSelectedWorkspace() !== undefined &&
			this.workspaceService.getSelectedWorkspace() !== null
		) {
			isValidEngagementId = true;
		}

		if (isValidEngagementId) {
			return true;
		}
		alert('Invalid Engagement');
		this.router.navigate([ '/home' ]);
		return false;
	}
}
