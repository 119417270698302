import { ConfirmComponent } from 'src/app/shared/confirm/confirm.component';
import { Labels } from 'src/app/locale/en/labels.en';
import { ModalSizes } from '@ey-xd/ng-motif';

export function checkDirtyState(component: any): Promise<boolean> {
  if (component.myForm.dirty || component.cellDirty) {

    const motifModalRef=component.motifModal.open(ConfirmComponent, {
      id: "unsavedChangesPopUp",
      data: {
        initialState: {
          title: Labels.ModalUnsavedTitle,
          content:  component.showAllQuestions !== undefined &&
          !component.showAllQuestions
            ? Labels.ModalUnsavedContentQuestion
            : Labels.ModalUnsavedContent,
          confirmBtnName: Labels.ModalUnsavedConfirmBtnName,
          cancelBtnName: Labels.ModalUnsavedCancelBtnName,
          isDeleteButton: false
        },
        backdrop: 'static',
        keyboard: false,
      },
      size: ModalSizes.CUSTOM,
      maxWidth:'45vw',
      width : '44%',
    });
    
    motifModalRef.addPanelClass('unsaved-modal');
    return new Promise(resolve => {
      motifModalRef.afterClosed().subscribe(result => {
        if (result) {
          resolve(true);
        }
      });
    });

    
  } else {
    return Promise.resolve(true);
  }
}