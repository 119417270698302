import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Notes } from '../models/report.model';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SettingsProvider } from '../settingsProvider';
import { environment } from 'src/assets/client-config';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  private NoteApiUrl = environment.apiUrl + '/api/Notes/';
  public notes = new BehaviorSubject<any | null>(null);
  constructor(private http: HttpClient, private settings: SettingsProvider) {}

  getNotes(): Observable<Notes[]> {
    return this.http.get<Notes[]>(this.NoteApiUrl).pipe(
      tap(data => data),
      catchError(this.handleError<Notes[]>('getNotes'))
    );
  }

  getRecentNotes(): Observable<Notes[]> {
    return this.http.get<Notes[]>(this.NoteApiUrl + 'GetRecentNotes/5').pipe(
      tap(data => data),
      catchError(this.handleError<Notes[]>('getRecentNotes'))
    );
  }

  getNote(id): Observable<Notes> {
    return this.http.get<Notes>(this.NoteApiUrl + id).pipe(
      tap(data => data),
      catchError(this.handleError<Notes>('getNote'))
    );
  }

  saveNote(note) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<Notes>(this.NoteApiUrl, note, options);
  }

  updateNote(note) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .put<Notes>(this.NoteApiUrl + note.guid, note, options)
      .pipe(tap(data =>data));
  }

  deleteNote(note) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .delete<Notes>(this.NoteApiUrl + note.guid, options)
      .pipe(catchError(this.handleError<Notes>('deleteNote')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }
}
