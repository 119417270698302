import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MOTIF_MODAL_DATA, MotifModal, SelectionType, } from '@ey-xd/ng-motif';
import { Subscription } from 'rxjs';
import { UsermanagementService } from 'src/app/_services/usermanagement.service';
import { AlertsComponent } from 'src/app/shared/alerts/alerts.component';
// VENDORS
import {
  UntypedFormArray, 
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import {
  AdUser,
  User,
  UserEngagementRole
} from 'src/app/models/user.model';
// import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Labels } from 'src/app/locale/en/labels.en';
import { ToasterService } from 'src/app/shared/toast/toaster.service';
// import { error } from 'util';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css'],
 
})
export class CreateUserComponent implements OnInit,AfterViewInit,AfterViewChecked {
  isLoading: boolean;
  title: string;
  closeBtnName: string;
  saveBtnName: string;
  userName: string;
  userId: string;
  newUserForm: UntypedFormGroup;
  workspaceList: any[];
  roles: any[];
  deletedRole: UserEngagementRole[] = [];
  alerts: any[] = [];
	@ViewChild('appAlert', { static: true }) alert: AlertsComponent;
  patternEmail = /^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
  userDataSource: Observable<any>;
  selectedUser: AdUser;
  motifTypeahead=this.motifData?.initialState.usersdata;
  dynamicDataSet: Array<string> = [];
  selectionType = SelectionType;
  search8;
  form: UntypedFormGroup;
  private _subs: Array<Subscription> = [];
  constructor(
    private fb: UntypedFormBuilder,
    private userService: UsermanagementService,
    public motifmodal: MotifModal,
    private cdr: ChangeDetectorRef,
    private toastr: ToasterService,
     @Inject(MOTIF_MODAL_DATA) public motifData: any,
  ) {
    this.isLoading = true;
    this.title=motifData?.initialState.title;
    this.userId=motifData?.initialState.userId;
    this.saveBtnName=motifData?.initialState.title;
    this.closeBtnName="Cancel"
    this.newUserForm = this.fb.group({
      user: new UntypedFormControl(),
      workSpaceRoles: new UntypedFormArray([])
    });
   
  }
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.createForm();
    }, 1);
  }

  ngOnInit() {
    this.loadRoles();
    this.loadWorkspace();
  }
  private _filterTypeaheadList(term: string, dataset: Array<string>): Array<string> {
    if (!term) return new Array<string>();
    return dataset?.filter((v) => (v ? v.toLowerCase().indexOf(term.toLowerCase()) > -1 : false))?.slice(0, 25);
}
  markUserAsError() {
    this.newUserForm.controls.user.setErrors({ error: true });
  }

  // typeaheadOnSelect(select) {
  //  
  //   const cal = select;
  //   this.newUserForm.controls.user.setValue(
  //     select.item.displayName + ' (' + select.item.mail + ')'
  //   );
  //   this.newUserForm.controls.user.markAsPristine();
  // }
  createForm() {   
    if (this.userId === undefined) {
      this.newUserForm = new UntypedFormGroup({
        user: new UntypedFormControl('', [
          Validators.required
        ]),
        workSpaceRoles: this.setworkSpaceRoles(null)

      });
    
    } else {
   
      this.newUserForm.get("user").setValue(this.motifData?.initialState.usersdata);
      this.userService.getUserRoles(this.userId).subscribe(
        usr => {         
          this.newUserForm = new UntypedFormGroup({
            user:this.setuser(usr.userName + ' (' + usr.emailId + ')'),
            workSpaceRoles: this.setworkSpaceRoles(usr.userEngagementRoles)
          });
        }
        ,
        error => {
          this.toastr.error('Server error!!!');
         // this.alert.DisplayMessage('Server error!!!', 'danger');
        }
      )
    }
  }
  loadUsers(e) {
    this.userService.searchUser(this.newUserForm.controls.user.value).pipe(
      map((clients: Array<AdUser>) =>
        clients?.map((client: AdUser) => `${client?.displayName} (${client?.mail})`)
      ),
      map((clients: Array<string>) => this._filterTypeaheadList(e.target.value, clients).slice(0).sort())
    ).subscribe((results) => {
      this.dynamicDataSet = Array.from(results);
    })
  }
  setworkSpaceRoles(x) {
    const arr = new UntypedFormArray([]);
    if (x !== null) {
      x.forEach(y => {
        arr.push(
          this.fb.group({
            engagement: [
              y.engagementId,
              [Validators.required, this.isEngagementSelected]
            ],
            role: [y.roleId, Validators.required],
            id: y.id
          })
        );
      });
    } else {
      arr.push(this.getNewRole());
    }
    return arr;
  }

  setuser(x) {
    const arr = new UntypedFormControl();
    if (x !== null) {
    arr.setValue(x)
    }
    return arr;
  }

  addValidators() {
    const arr = this.newUserForm.controls.workSpaceRoles as UntypedFormArray;
    arr.controls.forEach(element => {
      const control = element as UntypedFormGroup;
      control.controls.engagement.setValidators([
        Validators.required,
        this.isEngagementSelected
      ]);
      control.controls.engagement.updateValueAndValidity();
    });
  }

  getNewRole() {
    return this.fb.group({
      engagement: ['', [Validators.required, this.isEngagementSelected]],
      role: ['', Validators.required],
      id: 0
    });
  }

  addNewRole() { 
    const arr = this.newUserForm.controls.workSpaceRoles as UntypedFormArray;   
    arr.push(this.getNewRole());   
  }

  deleteRole(index) { 
    const arr = this.newUserForm.controls.workSpaceRoles as UntypedFormArray;   
    if (arr.length > 1) {
      const val = arr.controls[index] as UntypedFormGroup;     
      if (val.controls.id.value > 0) {
        this.deletedRole.push(val.value);        
      }
      arr.removeAt(index);
      this.newUserForm.markAsDirty();
    }
  }

  saveUserRoles(userForm) {   
    if (this.newUserForm.valid) {
      const user = new User();
      const usr = userForm.controls.user.value.split(' (');
      user.emailId = usr[1].substr(0, usr[1].length - 1);
      user.userName = usr[0];
      const userRole: UserEngagementRole[] = [];
      userForm.controls.workSpaceRoles.controls.forEach(element => {
        if (element.dirty) {
          const role = new UserEngagementRole();
          role.id = element.controls.id.value;
          role.engagementId = element.controls.engagement.value;
          role.roleId = element.controls.role.value;
          userRole.push(role);          
        }
      });

      if (this.deletedRole.length > 0) {
        this.deletedRole.forEach(del => {
          const role = new UserEngagementRole();
          role.id = del.id;
          role.engagementId = del.engagementId;
          role.roleId = del.roleId;
          role.isDeleted = true;
          userRole.push(role);
        });
      }

      if (userRole.length > 0) {
        user.userEngagementRoles = userRole;
        this.userService.SaveUserRoles(user).subscribe(() => {
          if (this.userId === undefined) {
            this.motifmodal.getModalById('createUser').close(true);
          } else {
            this.motifmodal.getModalById('updateUser').close(true);
          }          
          
        }, error=>{
          this.toastr.error('User already exists');
          this.motifmodal.getModalById('createUser').close(false);
        });
      }
      else{
        this.motifmodal.getModalById('updateUser').close(true);
        }
    }
  }

  loadRoles() {
    this.userService.getRoles().subscribe(
      roles => {
        this.roles = roles;
      }
      ,
      error => {
       // this.alert.DisplayMessage('Server error!!!', 'danger');
        this.toastr.error(Labels.ServerError);
      }
    );
  }

  loadWorkspace() {
    this.userService.getWorkspace().subscribe(
      ws => {
        this.workspaceList = ws;
      }
      ,
      error => {
        //this.alert.DisplayMessage('Server error!!!', 'danger');
        this.toastr.error(Labels.ServerError);
      }
    );
  }

  isEngagementSelected(input: UntypedFormControl) {
    if (input.dirty && input.value !== '' && input.value!=null && input.value!=undefined) {
      const form = input.root as UntypedFormGroup;
      if (form.controls !== undefined) {
        const arr = form.controls.workSpaceRoles as UntypedFormArray;
        const selectedEngagement = [];

        arr.controls.forEach(element => {
          if (element.value.engagement !== '') {
            selectedEngagement.push(+element.value.engagement);
          }
        });
        
        const selected = selectedEngagement.indexOf(+input.value);
      
        return selected > -1 ? { engagementSelected: true } : null;
        
      }
    }
    return null;
  }
  
  /**
 * Control Validators
 * @controlName {string}
 * @return {boolean}
 * */
  validator(controlName: string) {
    return this.newUserForm?.controls[controlName].invalid
      &&
      this.newUserForm.controls[controlName].dirty
      &&
      this.newUserForm.controls[controlName].hasError('required')
  }
    
  validateUser(controlName: string){
    if(this.newUserForm.controls[controlName].value!=null){
    if(this.dynamicDataSet.includes(this.newUserForm.controls[controlName].value)){
      return false;
    }
    else{
      if(this.newUserForm.controls[controlName].value.length>0){
      return true;
      }
    }
    }
  }
}
