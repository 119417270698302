import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { UserEngagementEntitlement } from '../models/user.model';
import { tap, catchError } from 'rxjs/operators';
// import { AdalService } from 'adal-angular4';
import { SettingsProvider } from '../settingsProvider';
import { environment } from 'src/assets/client-config';
import { SessionService } from '../session_management/session-service.service';

@Injectable({
  providedIn: 'root'
})
export class EntitlementService {
  private userApiUrl = environment.apiUrl + '/api/Users/';
  userEngagementEntitlement: UserEngagementEntitlement[];
  public reset = false;

  constructor(private http: HttpClient, private sessionService: SessionService) {
    const onPremises = environment.onPremises;
    this.sessionService.sessionId.subscribe(res=>{
      if(res){
        this.loadUserRolesEntitlement();
      }
    })
  
  }

  getRolesEntitlement(): UserEngagementEntitlement[] {
    return this.userEngagementEntitlement;
  }

  loadUserRolesEntitlement(): UserEngagementEntitlement[] {
    if (this.userEngagementEntitlement !== undefined) {
      return this.userEngagementEntitlement;
    }

    this.getUserRolesEntitlement().subscribe(
      (userEngagementEntitlement) => {
        this.userEngagementEntitlement = userEngagementEntitlement;
      },
      () => {
      }
    );
    return this.userEngagementEntitlement;
  }

  refreshUserRolesEntitlement(): UserEngagementEntitlement[] {
    this.getUserRolesEntitlement().subscribe(
      (userEngagementEntitlement) => {
        this.userEngagementEntitlement = userEngagementEntitlement;
      },
      () => {
      }
    );
    return this.userEngagementEntitlement;
  }

  getUserRolesEntitlement(): Observable<UserEngagementEntitlement[]> {
    return this.http
      .get<UserEngagementEntitlement[]>(this.userApiUrl + 'GetUserRolesEntitlement')
      .pipe(tap((data) => data), catchError(this.handleError<UserEngagementEntitlement[]>()));
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }
}
