import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {
  alerts: any[] = [];
  constructor() { }

  ngOnInit() {
  }

  DisplayMessage(message: string, type: string) {
    this.alerts.push({
      type: type,
      msg: message,
      timeout: type === 'danger' ? 0 : 3000
    });
  }

}
