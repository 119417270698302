export class Summary {
  id: number;
  objectives: string;
  objectivesJson: string;
  approach: string;
  approachJson: string;
  interviews: string;
  interviewsJson: string;
  overview: string;
  overviewJson: string;
  summaryResult: string;
  summaryResultJson: string;
}

export class QuestionSummary {
  functionalAreaName: string;
  functionalAreaGuid: string;
  functionalAreaCnt: number;
  questionCount: number;
  responseTypeYesCnt: number;
  responseTypeNoCnt: number;
  responseTypePartiallyCnt: number;
  responseTypeNACnt: number;
  impactHighCnt: number;
  impactMediumCnt: number;
  impactLowCnt: number;
  criticalityTenCnt: number;
  criticalitySevenCnt: number;
  criticalityFiveCnt: number;
  criticalityOneCnt: number;
}

export class KeyActivity {
  keyActivity: string;
  dueDate: Date;
  status: string;
  guid: string;
}

export class ExecutiveSummary {
  description: string;
  recommendation: string;
  recommendationNextSteps: string;
  executiveSummaryIdentifier: string;
  firstKeyWord: string;
  secondKeyWord: string;
  startDate: Date;
  dueDate: Date;
}

export class Notes {
  note: string;
  guid: string;
  createdOn: Date;
}

export class Appendix1Content {
  area: string;
  nyseNasdaq: string;
  londonStock: string;
  bseNse: string;
}

export class ToCContent {
  pageNumber: string;
  section: string;
  indentLevel: number;
}

export class AppendixInterviewListContent {
  area: string;
  personName: string;
  personDesignation: string;
}
