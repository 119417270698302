import { Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { QuestionMasterService } from '../../../_services/question-master.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Labels } from 'src/app/locale/en/labels.en';
import { ConfirmComponent } from '../../../shared/confirm/confirm.component';
import { combineLatest, Subscription } from 'rxjs';
import { PubSubService } from 'src/app/_services/pubsub.service';
import { CreateQuestionComponent } from '../create-question/create-question.component';
import { UtilityService } from '../../../_services/utility.service';
import { ModalSizes, MotifModal, MotifModalRef } from '@ey-xd/ng-motif';
import { ToasterService } from 'src/app/shared/toast/toaster.service';

@Component({
  selector: 'app-questionnairelibrary-gridrenderer',
  template: `
    <div class="gridquestionlabel"
      *ngIf="
        params.column === 'description' && params.data.descriptionJson === null
      "
    >
      <span> {{ params.data.description }} </span><br />
    </div>
    <div class="gridquestionlabel"
      *ngIf="
        params.column === 'description' && params.data.descriptionJson !== null
      "
    >
      <div [innerHTML]="jsontxt"></div>
    </div>
    <div
      *ngIf="
        params.column === 'checkbox' && params.data.isAddedToSetup === 'true'
      "
    >
      <img src='../../assets/eyOsg/images/common/png/circled-b.png' />
    </div>
    <div *ngIf="params.column === 'action'">
      <span
        class="material-icons actionIcons"
        aria-label="Edit"
        i18n-aria-label="Edit question button aria label | Setup question - Edit question label@@SetupQuesEditQuesLabel"
        style="cursor: pointer;color: black;margin: 0 5px;"
        (click)="OpenUpdateQuestionModal(this.params.data.id)"
      >
        edit
      </span>
      <span
        class="material-icons actionIcons"
        aria-label="Delete"
        i18n-aria-label="Delete question button aria label | Setup question - delete question label@@SetupQuesDeleteQuesLabel"
        style="cursor: pointer;color: black;margin: 0 5px;"
        (click)="OpenDeleteConfirmation(this.params.data.id)"
      >
        delete
      </span>
    </div>
    <div *ngIf="params.column === 'benchmark'">
      <img *ngIf="params.data.enableBenchmarking" src='../../assets/eyOsg/images/common/png/circled-b.png' width="25px" />
    </div>
    <div *ngIf="params.column === 'frequncycount'">
    <span> {{ params.data.popularityScore }} </span><br />
    </div>
  `
})
export class QuestionnaireLibraryGridRendererComponent {
  public params: any;
  public jsontxt: any;
  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];
  selectedQuestion: any;
  public motifModalRef?: MotifModalRef<any>;
  constructor(
    private questionMasterService: QuestionMasterService,
    private modalService: BsModalService,
    private broadcaster: PubSubService,
    private changeDetection: ChangeDetectorRef,
    private utilityService: UtilityService,
    public motifmodal: MotifModal,
    private toastr: ToasterService
  ) { }

  agInit(params: any): void {
    this.params = params;
    if (this.params.data.descriptionJson !== null) {
      this.jsontxt = this.utilityService.deltaToHtml(this.params.data.description, this.params.data.descriptionJson);
    }
  }

  // Edit question popup
  OpenUpdateQuestionModal(quesId: any) {

    this.selectedQuestion = quesId;
    this.motifModalRef = this.motifmodal.open(CreateQuestionComponent, {
      id:"createQuestionComponent",
      data: {
        initialState: {
          title: Labels.UpdateQuestionModal,
          isQuestionMasterMode: true,
          addQuestionMode: false,
          id: this.selectedQuestion,
          closeBtnName : Labels.ModalDeleteCancelBtnName,
          saveBtnName : Labels.UpdateQuestionModal
        },
        backdrop: 'static',
        keyboard: false,
      },
    });

    this.motifModalRef.addPanelClass("addquestion");

    this.motifModalRef.afterClosed().subscribe(result => {
      if (result){
        this.gridRefresh('edit');
      //  this.isNotDragEvent = true;
      //  this.deleteQuestion();
      }
    });
    // this.SubscribePopUpEvent();

    // const modalOptions: ModalOptions = {
    //   backdrop: 'static',
    //   keyboard: false,
    //   initialState: {
    //     title: Labels.UpdateQuestionModal,
    //     isQuestionMasterMode: true,
    //     addQuestionMode: false,
    //     id: this.selectedQuestion
    //   }
    // };
    // this.modalRef = this.modalService.show(
    //   CreateQuestionComponent,
    //   modalOptions
    // );
    // this.modalRef.content.closeBtnName = Labels.ModalDeleteCancelBtnName;
    // this.modalRef.content.saveBtnName = Labels.UpdateQuestionModal;
  }

  // Delete question confirmation dialog
  OpenDeleteConfirmation(quesId: any) {
    this.selectedQuestion = quesId;
    this.motifModalRef = this.motifmodal.open(ConfirmComponent, {
      id:"deleteQuesToSetup",
      data: {
      initialState: {
        isDeleteButton:true,
        title: Labels.ModalDeleteBtnName,
            content: Labels.QuestionDeleteConfirm,
            confirmBtnName:  Labels.ConfirmDeleteBtn,
            cancelBtnName: Labels.ModalDeleteCancelBtnName
          },
      backdrop: 'static',
      keyboard: false
      },
      size: ModalSizes.XL,
    });
    this.motifModalRef.addPanelClass('deletequestion')
    this.motifModalRef.afterClosed().subscribe(result => {
      if (result){
      //  this.isNotDragEvent = true;
       this.deleteQuestion();
      }
    });
    // this.SubscribePopUpEvent();
    // const modalOptions: ModalOptions = {
    //   backdrop: 'static',
    //   keyboard: false,
    //   initialState: {
    //     title: Labels.ModalDeleteConfirmBtnName,
    //     content: Labels.QuestionDeleteConfirm
    //   }
    // };
    // this.modalRef = this.modalService.show(ConfirmComponent, modalOptions);
    // this.modalRef.content.isCancelPrimary = false;
    // this.modalRef.content.confirmBtnName = Labels.ConfirmDeleteBtn;
    // this.modalRef.content.cancelBtnName = Labels.ModalDeleteCancelBtnName;
  }

  deleteQuestion() {
    this.questionMasterService
      .deleteSingleQuestionMaster(this.selectedQuestion)
      .subscribe(
        data => {
          this.toastr.success(Labels.QuestionDeletedSuccessfully);
          this.gridRefresh('delete');
        },
        err => {
          this.broadcaster.$pub('REFRESH_QUES_LIB_GRID', {
            Action: 'deleteError'
          });
        }
      );
  }

  SubscribePopUpEvent() {
    const _combine = combineLatest(this.modalService.onHide).subscribe(() =>
      this.changeDetection.markForCheck()
    );
    this.subscriptions = [];
    this.subscriptions.push(
      this.modalService.onHide.subscribe((refresh: string) => {
        if (refresh === 'save') {
        } else if (refresh === 'confirm') {
          this.deleteQuestion();
        } else if (refresh === 'decline') {
          // no action
        }
        this.UnsubscribePopUpEvent();
      })
    );
    this.subscriptions.push(_combine);
  }

  UnsubscribePopUpEvent() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  gridRefresh(ev) {
    this.questionMasterService.gridRefresh.next(true);
    if(ev = 'edit')
      this.broadcaster.$pub('REFRESH_QUES_LIB_GRID', { Action: 'editSuccess' });
    else{
      this.broadcaster.$pub('REFRESH_QUES_LIB_GRID', { Action: 'deleteSuccess' });
    }
  }
}
