<form [formGroup]="newNoteForm" class="notes-form" autocomplete="off" novalidate>
  <motif-modal-title>
    <h3> {{title}} </h3>
    <button class="close-btn" motifIconButton aria-label="Close modal" [motif-modal-close]="false">
      <motif-icon class="motif-modal-header-close-icon"
        [src]="'/assets/icons/navigation/ic_close_24px.svg'"></motif-icon>
    </button>
  </motif-modal-title>
  <motif-modal-content>
    <div class="motif-row">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <motif-form-field>
          <label motifLabel>Notes</label>
          <textarea motifInput formControlName="note" name="note" aria-label="textarea" class="noteTextArea" required
            maxlength="1000" (input)="spaceValidator('note')"></textarea>
          <motif-error *ngIf="validator('note')">
            Required field
          </motif-error>
          <motif-error *ngIf="newNoteForm?.controls.note.hasError('spaceIssue')">{{spaceMsg}}</motif-error>
          <motif-error *ngIf="newNoteForm?.controls.note.hasError('patternIssue')">{{patternMsg}}</motif-error>  
        </motif-form-field>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6" style="overflow-y:auto !important;height: 380px;">
        <motif-form-field>
          <p class="motif-h5">Recent notes</p>
          <motif-comment *ngFor="let note of recentNotes; index as i" [avatar]="true">
            <div class="motif-comment-container">
              <motif-comment-content>
                <div class="body2">
                  {{ note.note | slice: 0:(note.show ? note.note?.length : 200) }}
                  <span *ngIf="note.note.length > 200 && !note.show">...</span>
                  <a *ngIf="note.note.length > 200" class="showmorebtn" (click)="note.show = !note.show">
                    {{ note.show ? showLessLabel : showMoreLabel }}
                  </a>
                </div>
                <div class="body5" style="padding-top:5px ">
                  {{ note.createdOn | date: 'MMM dd, yyyy | hh:mm:ss' }}
                </div>
              </motif-comment-content>
            </div>
          </motif-comment>
        </motif-form-field>
      </div>
    </div>
  </motif-modal-content>
  <motif-modal-actions>
    <button type="submit" motifButton [disabled]="!newNoteForm?.dirty || newNoteForm?.invalid" color="primary-alt"
      (click)="saveNote()" [motif-modal-close]="true" aria-label={{saveBtnName}} role="button">{{saveBtnName}}</button>
    <button motifButton motif-modal-close color="secondary" aria-label={{closeBtnName}}
      role="button">{{closeBtnName}}</button>
  </motif-modal-actions>
</form>