import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NotificationType, Toaster } from './toaster.interface';

@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    private toaster = new Subject<Toaster>()
    constructor() { }
    // Success message
    success(message: string) {
        this.toaster.next(<Toaster>{ type: NotificationType.Success, message: message })
    }

    // Error message
    error(message: string) {
        this.toaster.next(<Toaster>{ type: NotificationType.Error, message: message })
    }

    // Info message
    info(message: string) {
        this.toaster.next(<Toaster>{ type: NotificationType.Info, message: message })
    }
    // Warning message
    warning(message: string) {
        this.toaster.next(<Toaster>{ type: NotificationType.Warning, message: message })
    }

    // Clear all
    clear() {
        this.toaster.next(null);
    }

    getToaster(): Observable<any> {
        return this.toaster.asObservable();
    }
}
