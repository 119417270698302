import { Component, Inject, OnInit } from '@angular/core';
import { Notes } from 'src/app/models/report.model';
import { NotesService } from 'src/app/_services/notes.service';
import { Labels } from 'src/app/locale/en/labels.en';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MOTIF_MODAL_DATA, MotifModal } from '@ey-xd/ng-motif';
import { ToasterService } from 'src/app/shared/toast/toaster.service';
import { ValidationConstant } from 'src/app/constants/validation-constant';

@Component({
  selector: 'app-create-notes',
  templateUrl: './create-notes.component.html',
  styleUrls: ['./create-notes.component.css']
})
export class CreateNotesComponent implements OnInit {
  newNote = new Notes();
  notes: Notes[];
  recentNotes: Notes[];
  title: string;
  closeBtnName: string;
  saveBtnName: string;
  id: number;
  guid: string;
  alerts: any[] = [];
  showMoreLabel = 'Show More';
  showLessLabel = 'Show less';
  newNoteForm: UntypedFormGroup;
  pattern1=ValidationConstant.Pattern;
  patternMsg=ValidationConstant.PatternIssueMsg;
  spaceMsg=ValidationConstant.SpaceIssueMsg;
  constructor(
    private notesService: NotesService,
    public modal: MotifModal,
    private fb: FormBuilder,
    @Inject(MOTIF_MODAL_DATA) motifData: any,
    private toastr: ToasterService
  ) {
    this.title = motifData?.initialState.title;
    this.guid = motifData?.initialState.guid;
    this.closeBtnName = motifData?.initialState.closeBtnName;
    this.saveBtnName = motifData?.initialState.saveBtnName;
  }

  ngOnInit() {
    this.showLessLabel = Labels.ModalLinkShowLess;
    this.showMoreLabel = Labels.ModallinkShowMore;
    this.loadNote();
    this.generateForm();
  }

  loadNote() {
    if (this.guid !== '') {
      this.notesService.getNote(this.guid).subscribe(
        res => {
          // this.newNote = note;
          this.newNoteForm.patchValue({
            note: res.note
          })
        },
        error => {
          this.toastr.error(Labels.ServerErrorOnLoad);
        }
      );
    }
    this.notesService.getRecentNotes().subscribe(
      note => {
        this.recentNotes = note;
      },
      error => {
        this.toastr.error(Labels.ServerErrorOnLoad);
      }
    );
  }

  generateForm() {
    this.newNoteForm = this.fb.group({
      note: ['', Validators.required]
    })
  }

  validator(controlName: string) {
    return this.newNoteForm?.controls[controlName].invalid
      &&
      this.newNoteForm.controls[controlName].dirty
      &&
      this.newNoteForm.controls[controlName].hasError('required')
  }
  spaceValidator(controlName: string) {
    let spaceflag= this.newNoteForm?.controls[controlName].value.startsWith(' ') || 
    this.newNoteForm?.controls[controlName].value.endsWith(' ');

    let pattern =this.pattern1;
    let patternFlag= pattern.test(this.newNoteForm?.controls[controlName].value);

    let requiredflag= this.newNoteForm?.controls[controlName].value == ''? true:false;

    if(!(!spaceflag && patternFlag && !requiredflag))
    this.newNoteForm?.controls[controlName].setErrors({spaceIssue: spaceflag, patternIssue: !patternFlag, required:requiredflag});
  }

  saveNote() {
    let payload = this.newNoteForm.value;
    payload.note ? payload.note = payload.note.trim() : payload;
    if (this.guid !== '') {
      payload.guid = this.guid;
      this.notesService.updateNote(payload).subscribe(
        () => {
          this.toastr.success(Labels.ModalUpdateNoteSuccess);
          this.notesService.getNotes().subscribe(
            notes => {
              this.notes = notes;
          this.notesService.notes.next(this.notes);
            },           
            (error: any) => {        
              if(error.includes("validation errors"))
                this.toastr.error(Labels.ValidationError)
              else   
              this.toastr.error(Labels.ServerError); 
            }   
          );
        },
        (error: any) => {        
          if(error.includes("validation errors"))
            this.toastr.error(Labels.ValidationError)
          else   
          this.toastr.error(Labels.ServerErrorOnSave); 
        }  
      );
    } else {
      this.notesService.saveNote(payload).subscribe(
        () => {
          this.toastr.success(Labels.ModalCreateNoteSuccess);
          this.notesService.getNotes().subscribe(
            notes => {
              this.notesService.notes.next(notes);
            },
            (error: any) => {        
              if(error.includes("validation errors"))
                this.toastr.error(Labels.ValidationError)
              else   
              this.toastr.error(Labels.ServerError); 
            }   
          );
        },
        (error: any) => {        
          if(error.includes("validation errors"))
            this.toastr.error(Labels.ValidationError)
          else   
          this.toastr.error(Labels.ServerErrorOnSave); 
        }   
      );
    }
  }
}
