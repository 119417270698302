import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/switchMap';
// import 'rxjs/add/operator/take';
import { EntitlementService } from '../_services/entitlementService';
import { LoggingService } from '../_services/logging.service';
// import { AdalService } from 'adal-angular4/adal.service';

@Injectable({
  providedIn: 'root'
})
export class EntitlementsAuthorizationGuard implements CanActivate {
  userEngagementEntitlement: any;
  constructor(
    private entitlementService: EntitlementService,
    private router: Router,
    // private adalService: AdalService,
    private logservice: LoggingService
  ) {
    this.userEngagementEntitlement = this.entitlementService.getRolesEntitlement();
  }

  canActivate(): Promise<boolean> {
    let entitled = false;

    return new Promise(resolve => {
      this.entitlementService
        .getUserRolesEntitlement()
        .subscribe(userEngagementEntitlement => {
          this.userEngagementEntitlement = userEngagementEntitlement;

          const entitlementsArray = ['AU', 'EU', 'DU'];

          if (
            this.userEngagementEntitlement === undefined ||
            this.userEngagementEntitlement.length === 0
          ) {
            // this.logservice.info(
            //   'Unauthorized access - User Management blocked to ' +
            //     this.adalService.userInfo.userName
            // );
            this.router.navigate(['/unauthorized']);
          } else {
            this.userEngagementEntitlement.forEach(element => {
              element.userEntitlementRoleVM.forEach(entitlement => {
                if (entitlementsArray.indexOf(entitlement.code) > -1) {
                  entitled = true;
                  resolve(true);
                }
              });
            });
            if (!entitled) {
              // alert('Access denied!');
              // this.logservice.info(
              //   'Unauthorized access - User Management blocked to ' +
              //     this.adalService.userInfo.userName
              // );
              this.router.navigate(['/unauthorized']);
            } else {
              resolve(true);
            }

            resolve(false);
          }
          resolve(false);
        });
    });
  }
}
