import { Injectable } from '@angular/core';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor() {}

  getDeltaJson(content: string, contentJson: string): string {
    if (contentJson === null || contentJson === '') {
      const delta = {
        ops: [{ insert: content === null ? '' : content }]
      };
      return JSON.stringify(delta);
    }
    return contentJson;
  }

  deltaToHtml(content: string, contentJson: string): string {
    let delta;
    if (contentJson === null || contentJson === '') {
      delta = {
        ops: [{ insert: content }]
      };
    } else {
      delta = JSON.parse(contentJson);
    }

    const converter = new QuillDeltaToHtmlConverter(delta.ops);
    return converter.convert();
  }
}
