import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Workspace } from './workspace';
import { SettingsProvider } from '../settingsProvider';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/assets/client-config';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  private workspaceSelected: Workspace;
  private workspaceSelectedGuid: string;
  public loggedInUser = new BehaviorSubject<string>('');
  public routeChanged = new BehaviorSubject<boolean>(true);
  public popupOpened = new BehaviorSubject<boolean>(false);

  workspaces = new BehaviorSubject<Workspace[]>([]);
  private workspaceUrl = environment.apiUrl + '/api/Engagement/';

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  setSelectedWorkspace(value: Workspace) {
    this.workspaceSelected = value;
    // localStorage.setItem('workspaceSelected', JSON.stringify(value.guid));
    if (this.cookieService.check('workspaceSelected') === true) {
      this.cookieService.delete('workspaceSelected', '/');
    }
    this.cookieService.set('workspaceSelected', btoa(JSON.stringify(value)), undefined , '/');
  }
  setCurrentUser(value: string) {
    this.loggedInUser.next(value);
  }

  getCurrentUser() {
    return this.loggedInUser.asObservable();
  }
  getSelectedWorkspace(): Workspace {
    if (this.workspaceSelected === undefined) {
      // const wsSelected = localStorage.getItem('workspaceSelected');
      if (this.cookieService.check('workspaceSelected') === true) {
      const wsSelected = this.cookieService.get('workspaceSelected');
      this.workspaceSelected = JSON.parse(atob(wsSelected));
      }
      /* this.getWorkspace(this.workspaceSelectedGuid).subscribe(
        workspace => {
          this.workspaceSelected = workspace;
        },
        error => {
         //  this.DisplayMessage(Labels.ServerError, 'danger');
        }
      );*/
     /*
     this.http
      .get<Workspace>(this.workspaceUrl + this.workspaceSelectedGuid)
      .pipe(tap((data) => this.workspaceSelected = data), catchError(this.handleError<Workspace>('getWorkspace')))

      */
    }
    return this.workspaceSelected;
  }

  getWorkspaces(): Observable<Workspace[]> {
    return this.http.get<Workspace[]>(this.workspaceUrl).pipe(tap((data) => data));
  }

  getWorkspace(id): Observable<Workspace> {
    return this.http
      .get<Workspace>(this.workspaceUrl + id)
      .pipe(tap((data) => data), catchError(this.handleError<Workspace>('getWorkspace')));
  }

  saveWorkspace(workspace) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<Workspace>(this.workspaceUrl, workspace, options);
  }

  updateWorkspace(workspace, guid?) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    if(guid){
      return this.http
      .put<Workspace>(this.workspaceUrl + guid, workspace, options);
      // .pipe(catchError(this.handleError<Workspace>('updateWorkspace')));
    }
    else{
      return this.http
      .put<Workspace>(this.workspaceUrl + workspace.guid, workspace, options);
      // .pipe(catchError(this.handleError<Workspace>('updateWorkspace')));
    }

  }

  deleteWorkspace(workspace) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .delete<Workspace>(this.workspaceUrl + workspace.guid, options)
      .pipe(catchError(this.handleError<Workspace>('saveWorkspace')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  resetEngagement(oldEngId: any, workspace) {
    const url = this.workspaceUrl + 'ResetEngagement/' + oldEngId;
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<Workspace>(url, workspace, options);
  }

  getPrivacyPolicy(engagementId) {
    const privacyPolicyUrl = environment.apiUrl + '/api/PrivacyPolicy/GetPrivacyPolicy/';
    return this.http.get(`${privacyPolicyUrl}${engagementId}`).pipe(tap((data) => data));
  }

  acceptPrivacyPolicy(payload) {
    const privacyPolicyUrl = environment.apiUrl + '/api/PrivacyPolicy/SavePrivacyPolicy';
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<Workspace>(privacyPolicyUrl, payload, options);
  }
}
