import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { error } from "console";
import { BehaviorSubject, catchError, tap } from "rxjs";
import { environment } from "src/assets/client-config";

@Injectable({
    providedIn: 'root'
})
export class QuestionUploadService {
    private questionUploadApiUrl = `${environment.apiUrl}/api/UploadQuestionLibrary`
    isLoading = new BehaviorSubject<boolean>(false);
    constructor(private http: HttpClient) {

    }
    //CheckAccess
    checkAccess() {
        return this.http
            .get<any>(this.questionUploadApiUrl + '/CheckAccess')
            .pipe(tap((data) => data));
    }

    //Upload
    uploadQuestionFile(payload) {
        const headersObj = new HttpHeaders().set('Accept', '*/*');
        return this.http.post(this.questionUploadApiUrl + '/Upload', payload, {
            headers: headersObj,
        });

    }
}

