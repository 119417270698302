<motif-modal-title>
    <h4><motif-icon class="warn" [src]="'/assets/icons/alert/ic_error_24px.svg'"></motif-icon> {{title}} </h4>
    <button class="close-btn" motifIconButton aria-label="Close modal" [motif-modal-close]="false">
      <motif-icon class="motif-modal-header-close-icon" [src]="'/assets/icons/navigation/ic_close_24px.svg'"></motif-icon>
    </button>
  </motif-modal-title>
  <motif-modal-content>
    <div class="modal-body text-left">
  
      <span [innerHtml]="content"></span>
    </div>
  </motif-modal-content>
  <motif-modal-actions>
    <button (click)="deleteWorkspace()" motifButton color="primary-alt" [motif-modal-close]="true" aria-label="Yes, Confirm"
      role="button">{{confirmBtnName}}</button>
    <button motifButton motif-modal-close color="secondary" aria-label="Cancel" role="button">{{cancelBtnName}}</button>
  </motif-modal-actions>