<div *ngIf="this.params.data.roleId !== 2">
  <span
    *ngIf="this.params.context.componentParent.hasEditAccess"
    class="material-icons actionmaticon"
    aria-label="Edit"
    title="Edit"
    (click)="invokeEditMethod()"
    data-action-type="edit"
    i18n-title="Edit Title | Edit Title in title@@editTitleTooltip"
  >
    edit
  </span>
  <span
    *ngIf="this.params.context.componentParent.hasDeleteAccess"
    class="material-icons actionmaticon"
    aria-label="Delete"
    style="margin-left: 20px"
    title="Delete"
    (click)="invokeDeleteMethod()"
    data-action-type="delete"
    i18n-title="Delete Title | Delete Title in title@@deleteTitleTooltip"
  >
    delete
  </span>
</div>
