<!-- <div>
  <span
    class="medbody"
    i18n="Access Denied label | Access Denied in bar@@accessDeniedLabel"
    >Access Denied</span
  >
  <span
    class="smallbody"
    i18n="Access Denied Description label | Access Denied in bar@@accessDeniedDescLabel"
    >You have not been provided access to IPO Enabler. Please contact an
    administrator for access.
  </span>
</div> -->
<div class="motif-container accessdenied">                
  <div class="iconblock">
      <motif-icon [src]="'/assets/icons/content/ic_block_24px.svg'"></motif-icon>
  </div>
  <div class="textaccess">
        Access denied
  </div>
  <div class="textdetail">               
       You do not have access to IPO Enabler. Please get in touch with the administrator to request access.                 
   </div>
</div>
