import { Component, OnInit } from '@angular/core';
import { ToasterService } from './toaster.service';
import { Toaster } from './toaster.interface';
@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  public toasters: Toaster[] = [];
  public duration: number = 5000;

  constructor(
    private toasterService: ToasterService
  ) {
    /** In order to fetch toasters from toaster service */
    this.toasterService.getToaster().subscribe((_toaster: Toaster) => {
      this.toasters.push(_toaster);
      setTimeout(() => this.removeToaster(_toaster), this.duration);
    }, (error: Error) => console.error(error));
  }

  ngOnInit(): void {
  }

  /** In order to remove toaster from toaster array */
  removeToaster(toaster: Toaster) {
    this.toasters = this.toasters.filter(_toaster => {
      return _toaster !== toaster
    });
  }
}
