import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-gridactionrenderer',
  templateUrl: './gridactionrenderer.component.html',
  styleUrls: ['./gridactionrenderer.component.css']
})
export class GridactionrendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  public invokeEditMethod() {
    this.params.context.componentParent.openUpdateUserModel(
      this.params.data.userId
    );
  }

  public invokeDeleteMethod() {
    this.params.context.componentParent.openDeleteConfirmModal(
      this.params.data
    );
  }

  refresh(): boolean {
    return false;
  }
}
