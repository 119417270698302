import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-checkbox-ppt-renderer',
  templateUrl: './checkbox-ppt-render.component.html',
  styleUrls: ['./checkbox-ppt-render.component.css']
})

export class CheckBoxPPTRendererComponent implements ICellEditorAngularComp {
  public checked: any; 
  public params: any;

  agInit(params: any): void { 
    this.checked = params.value;
    this.params = params;
  } 

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
 
  getValue(): any { 
    return this.checked; 
  } 
 
  isPopup(): boolean { 
    return true; 
  } 

  onChange($event) {
    if (this.params.change instanceof Function) {

      let id = this.params.context.functionalAreaId;
      let rowIndex = this.params.rowIndex;
      this.params.context.formGroup.controls[id].controls.questionMetadatas.controls[rowIndex].controls['showDescriptionInPpt'].patchValue($event);
      this.params.setValue($event);

      const params = {
        event: $event,
        data: this.params.data,
        control: this.params.context.formGroup.controls[id].controls.questionMetadatas.controls[rowIndex].controls['showDescriptionInPpt']
      }

      params.control.markAsDirty();

      this.params.change(params);
    }
  }
} 