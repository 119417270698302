<h3>Question upload</h3>
<section>
    <form [formGroup]="form">
        <motif-form-field>
            <fieldset [ngClass]="{'error':typeError}" motif-file-uploader-container validationInteraction="touched">
                <input (change)="resetProject($event)" motif-file-uploader formControlName="files">
            </fieldset>
            <div>
                <span class="label">File name:</span>
                <span class="name">{{file?file.name:''}}</span>
                <article>
                    <button [disabled]="!file" motifButton (click)="uploadFile()" color="primary-alt">Upload</button>
                    <button [disabled]="!file" (click)="clearFileSelection()" motifButton
                        color="secondary">Clear</button>
                </article>
            </div>
            <motif-inline-message *ngIf="typeError" [type]="'error'">Only xlxs file format
                supported</motif-inline-message>
        </motif-form-field>
    </form>
</section>
<app-motif-progress-loader *ngIf="isLoading"></app-motif-progress-loader>