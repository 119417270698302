import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { FunctionalArea } from '../readiness/questionairrelibrary/questionMetadata';
import { SettingsProvider } from '../settingsProvider';
import { environment } from 'src/assets/client-config';

@Injectable({
  providedIn: 'root'
})
export class OwnerService {
  private functionalAreaUrl = environment.apiUrl + '/api/FunctionalArea/';

  constructor(private http: HttpClient, private setting: SettingsProvider) {}

  saveOwnerDetails(ownerDetails: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    const url = this.functionalAreaUrl + 'UpdateOwnerDetails'
    return this.http.put<FunctionalArea>(url, ownerDetails, options);
  }

  getOwnerDetails(faGuid: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    const url = this.functionalAreaUrl + 'GetOwnerDetails/' + faGuid;
    return this.http.get<FunctionalArea>(url, options);
  }
}
