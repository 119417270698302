import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ConfirmComponent } from './confirm/confirm.component';
import { AboutComponent } from './about/about.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { GridactionrendererComponent } from './gridactionrenderer/gridactionrenderer.component';
import { MotifProgressLoaderComponent } from './motif-progress-loader/motif-progress-loader.component';
import { MotifButtonModule, MotifIconModule, MotifModalModule, MotifProgressLoaderModule, MotifToastModule } from '@ey-xd/ng-motif';
import { ToastComponent } from './toast/toast.component';
import { DataLoaderComponent } from './data-loader/data-loader.component';

@NgModule({
  imports: [CommonModule, FormsModule,
    AlertModule.forRoot(), MotifProgressLoaderModule, MotifToastModule, MotifModalModule, MotifIconModule, MotifButtonModule],
  exports: [CommonModule, FormsModule, ConfirmComponent, AlertsComponent, MotifProgressLoaderComponent, AboutComponent, ToastComponent, DataLoaderComponent],
  declarations: [ConfirmComponent, AlertsComponent, GridactionrendererComponent, MotifProgressLoaderComponent, AboutComponent, ToastComponent, DataLoaderComponent]
})
export class SharedModule {
  
}
