export class Users {
  engagementRoleId: string;
  userId: string;
  userName: string;
  emailId: string;
  roleId: number;
  role: string;
  engagementId: number;
  engagementName: string;
}

export class User {
  id: string;
  userName: string;
  emailId: string;
  userEngagementRoles: UserEngagementRole[];
}

export class UserEngagementRole {
  id: number;
  roleId: number;
  engagementId: number;
  isDeleted: boolean;
}

export class UserEngagementEntitlement {
  EngagementId: string;
  userEntitlementRoleVM: UserEntitlement[];
}

export class UserEntitlement {
  code: string;
}

export class AdUser {
  displayName: string;
  givenName: string;
  mail: string;
  userPrincipalName: string;
}
