export const environment = {
  production: true,
  "agGridEnterpriseMode": true,
  "aggridEnterpriseLicenseKey": "EYGS_LLP_IPO_Enabler_3Devs31_July_2020__MTU5NjE1MDAwMDAwMA==66569f1cc49828024bf9b5bafaeca037",
  apiUrl: "https://ipoe-api.ey.com",
  "onPremises": true,
  "envName": "PRODUS",
  "hideBenchMarking": false,
  loginUrl:'/api/auth/setsession',
  renewSession: '/api/auth/renewsession',
  updateSession:'/api/session/sessionactivity',
  logout:'/api/auth/logout',
  saltConstant:'Xyc_Zm?Iv6QK)mAVoWzIl{s!s#_{W0g',
  msalConfig: {
    clientId: "f5f7f387-4ea6-4d5a-860e-b08cf78a47db",
    authority: `https://login.microsoftonline.com/ey.com`,
    redirectUri: 'https://ipoe.ey.com',
    postLogoutRedirectUri: 'https://ipoe.ey.com/logout',
    profileUrl: 'https://graph.microsoft-ppe.com/v1.0/me',
    apiClientId: '05b0c496-9fba-45d2-9511-6f2800095bb6'
  }
};
