import { Component, OnInit, Inject } from '@angular/core';
import { MOTIF_MODAL_DATA, MotifModal } from '@ey-xd/ng-motif';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  title: string;
  content: any; 
  cancelBtnName: string;
  isDeleteButton = false;

  constructor(
    public modal: MotifModal,
    @Inject(MOTIF_MODAL_DATA) motifData: any,
  ) {   
    
  }

  ngOnInit() { }

}
