<div>
  <span class="bigbody">
    404
  </span>
  <span
    class="medbody"
    i18n="Not Found label | Not Found in form@@notFoundLabel"
    >Not Found</span
  >
</div>
