import {HttpClientModule} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap, catchError } from 'rxjs/operators';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { of, Observable } from 'rxjs';

@Injectable()
export class SettingsProvider {
  private config: any;

  constructor(private handler: HttpBackend) {}

  public loadConfig() {
    const http = new HttpClient(this.handler);
    return http
      .get('../assets/client-config.ts')
      .toPromise()
      .then(res => {
        this.config = res;
      });
  }

  public get configuration(): any {
    return this.config;
  }
}
