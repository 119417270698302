import { Component, OnInit, Inject } from '@angular/core';
import { MOTIF_MODAL_DATA, MotifModal } from '@ey-xd/ng-motif';
import { WorkspaceService } from 'src/app/workspace/workspace.service';
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  title: string;
  content: any;
  confirmBtnName: string;
  cancelBtnName: string;
  isDeleteButton = false;
  popupOpen:boolean = false;
  constructor(
    public modal: MotifModal,
    private workspace: WorkspaceService,
    @Inject(MOTIF_MODAL_DATA) motifData: any,
  ) {
    this.title = motifData?.initialState.title;
    this.content = motifData?.initialState.content;
    this.confirmBtnName = motifData?.initialState.confirmBtnName;
    this.cancelBtnName = motifData?.initialState.cancelBtnName;
    this.isDeleteButton = motifData?.initialState.isDeleteButton;
    this.workspace.popupOpened.subscribe(item=>{
      this.popupOpen = item;
    })
    this.workspace.popupOpened.next(true);
    //this.popupOpen = !this.popupOpen;
  }

  ngOnInit() {
    // this.workspace.popupOpened.next(this.popupOpen);
    // this.popupOpen = !this.popupOpen;
   }

  confirmCancel(){
    this.workspace.routeChanged.next(false);
    this.workspace.popupOpened.next(true);
   // this.popupOpen = !this.popupOpen;
  }
  closePopup(){
    this.workspace.popupOpened.next(false)
  }
  ngOnDestroy(){
  }
}
