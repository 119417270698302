import { Component, ChangeDetectorRef, ViewChild,Inject } from '@angular/core';
import { QuestionMasterService } from '../../../_services/question-master.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Labels } from 'src/app/locale/en/labels.en';
import { combineLatest, Subscription } from 'rxjs';
import { PubSubService } from 'src/app/_services/pubsub.service';
import { UtilityService } from '../../../_services/utility.service';
import { UpdateHeatmapComponent } from './update-heatmap/update-heatmap.component';
import { MotifModal, MotifModalRef } from '@ey-xd/ng-motif';

@Component({
  selector: 'app-heatmap-gridrenderer',
  template: `

    <div *ngIf="params.column === 'action'">
      <span
        class="material-icons actionIcons"
        aria-label="Edit"
        style="cursor: pointer;color: black;margin: 0 5px;"
        (click)="OpenUpdateHeatmapModal(this.params.data.qid, this.params.data)"
      >
        edit
      </span>
    </div>
  `
})
export class HeatmapGridRendererComponent {
  public params: any;
  public jsontxt: any;
  heatmapId: any;
  subscriptions: Subscription[] = [];
  selectedQuestion: any;
  public motifModalRef?: MotifModalRef<any>;
  constructor(
    private questionMasterService: QuestionMasterService,
    private modalService: BsModalService,
    private broadcaster: PubSubService,
    private changeDetection: ChangeDetectorRef,
    private utilityService: UtilityService,
    public motifmodal: MotifModal,
  ) { }

  agInit(params: any): void {
    this.params = params;
  }

  // Edit question popup
  OpenUpdateHeatmapModal(quesId: any, data: any) {
    this.selectedQuestion = quesId;
    this.SubscribePopUpEvent();

    this.motifModalRef = this.motifmodal.open(UpdateHeatmapComponent, {
      id:"editFunctionalAreaDetails",
      data: {
      initialState: {
        title: Labels.EditFunctionalAreaDetails,
        isQuestionMasterMode: false,
        addQuestionMode: false,
        id: this.selectedQuestion,
        dataParams: this.params,
        closeBtnName: Labels.ModalDeleteCancelBtnName,
        saveBtnName: Labels.saveLabel,
          },
      backdrop: 'static',
      keyboard: false,
      }
    });
  }
  SubscribePopUpEvent() {
    const _combine = combineLatest(this.modalService.onHide).subscribe(() =>
      this.changeDetection.markForCheck()
    );
    this.subscriptions = [];
    this.subscriptions.push(
      this.modalService.onHide.subscribe((refresh: string) => {
        if (refresh === 'save') {
        } else if (refresh === 'confirm') {

        } else if (refresh === 'decline') {
          // no action
        }
        this.UnsubscribePopUpEvent();
      })
    );
    this.subscriptions.push(_combine);
  }

  UnsubscribePopUpEvent() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
}
