<header motif-header class="layout-header" appHeaderName="">
  <img motif-header-logo class="logo" alt="Application logo" src="./assets/images/ey-logo-inverted.svg" position="left">
  <span *ngIf="!isUnauthorized" motif-header-title class="p-l-10 headertitle" position="left"
    [routerLink]="['/home']">IPO Enabler</span>
  <span *ngIf="isUnauthorized" motif-header-title class="p-l-10 headertitle" position="left">IPO Enabler</span>
  <span *ngIf="env" motif-header-title class="p-l-10 envname" position="left">({{env.toUpperCase()}})</span>
  <ul class="nav navbar-nav test" position="right">
    <li style="cursor:pointer">
      <div class="mr-2 " motif-header-icon motifDropdown placement="bottom-right">
        <button [disabled]="isUnauthorized" motifIconButton aria-label="Dropdown toggle example"
          (click)="$event.stopPropagation()" motifDropdownToggle id="userGuide">
          <motif-icon [ngClass]="isUnauthorized ? 'disabled info' : 'info'"
            [src]="'/assets/icons/action/ic_info_outline_24px.svg'"></motif-icon>
        </button>
        <div class="user-guide" [ngClass]="isUnauthorized ? 'disabled' : ''" motifDropdownMenu
          aria-labelledby="userGuide">
          <button [ngClass]="isUnauthorized ? 'disabled userguide' : 'userguide'" motifDropdownItem
            (click)="about()">About IPO Enabler</button>
          <button [ngClass]="isUnauthorized ? 'disabled userguide' : 'userguide'" motifDropdownItem
            (click)="openUserGuide()">User Guide</button>
        </div>
      </div>
    </li>
    <li>
      <div motifDropdown motif-header-avatar placement="bottom-right">
        <button [disabled]="isUnauthorized" motifIconButton aria-label="Dropdown toggle example"
          (click)="preventNavigation($event)" motifDropdownToggle id="logout">
          <motif-avatar [ngClass]="isUnauthorized ? 'disabled avatar' : 'avatar'" *ngIf="!isProfilePic"
            [src]="profilePic" size="small" [userName]="name"></motif-avatar>
          <motif-avatar [ngClass]="isUnauthorized ? 'disabled avatar' : 'avatar'" *ngIf="isProfilePic"
            [src]="profilePic" size="small" [userName]="name"></motif-avatar>
        </button>
        <div class="logout" motifDropdownMenu aria-labelledby="logout">
          <button motifDropdownItem class="username">{{name}}</button>
          <button motifDropdownItem class="profile-options" (click)="showPrivacyPolicy()">Privacy Policy</button>
          <button motifDropdownItem class="profile-options" (click)="logout()"><img class="mr-1"
              src="./assets/logout.svg" alt="Logout" /> Logout</button>
        </div>
      </div>
    </li>
  </ul>
</header>

<nav motif-vertical-navigation [show]="showMenu" id="vertical-navigation-menu" class="navmenu">
  <ul motif-vertical-navigation-menu>
    <ng-container *ngFor="let item of menu; index as i">
      <ng-container
        *ngTemplateOutlet="!item.subMenu || item.subMenu?.length === 0 ? itemTemplate : subMenuTemplate; context: {item, index: i}">
      </ng-container>
    </ng-container>
    <ng-template #itemTemplate let-item="item">
      <li motif-vertical-navigation-menu-item
        *ngIf="item.routerLink=='/user'?hasUserEntitlement(['AU', 'EU', 'DU']):true">
        <a *ngIf="!isUnauthorized" (mouseout)='mouseOutcss($event)' motif-vertical-navigation-menu-link
          [routerLink]="item.routerLink" vertical-navigation-menu-item-active
          [verticalNavigationMenuItemActiveOptions]="{exact: item.exact}">
          <motif-icon *ngIf="item?.icon" [src]="item?.icon" tooltip-color="alternate" [hideIconClose]="true"
            [hideArrow]="true" placement="auto" motif-tooltip [customTemplate]="customTemplate"></motif-icon>
        </a>
        <ng-template #customTemplate>
          <div class="cstmtooltip">
            <label class="cstmtooltiphead">{{item?.displayLabel}}</label>
            <label class="cstmtooltipcontent">{{item?.label}}</label>
          </div>
        </ng-template>
        <a class="disabled" *ngIf="isUnauthorized" (mouseout)='mouseOutcss($event)' motif-vertical-navigation-menu-link
          vertical-navigation-menu-item-active [verticalNavigationMenuItemActiveOptions]="{exact: item.exact}">
          <motif-icon *ngIf="item?.icon" [src]="item?.icon" tooltip-color="alternate" [hideIconClose]="true"
            [hideArrow]="true" placement="auto"></motif-icon>
        </a>
      </li>
    </ng-template>
    <ng-template #subMenuTemplate let-item="item" let-index="index">
      <li motif-vertical-navigation-menu-item vertical-navigation-menu-item-active [useContextMenu]="useContextMenu">
        <a [ngClass]="isUnauthorized ? 'disabled' : ''" motif-vertical-navigation-menu-link
          [attr.aria-controls]="'submenu-' + index">
          <motif-icon *ngIf="item?.icon" [src]="item?.icon"></motif-icon>
          <span>{{item?.label}}</span>
        </a>
        <ul *ngIf="!isUnauthorized" motif-vertical-navigation-sub-menu position="right" id="submenu-{{index}}">
          <ng-container>
            <li motif-vertical-navigation-menu-item>
              <a motif-vertical-navigation-menu-link vertical-navigation-menu-item-active>
                <span>Quick Links</span>
              </a>
            </li>
            <li motif-vertical-navigation-menu-item *ngFor="let subItem of item.subMenu">
              <a *ngIf="subItem.label != 'Key EY Contacts' else keyEYContacts" motif-vertical-navigation-menu-link
                [href]="subItem.url" target="_blank" vertical-navigation-menu-item-active
                [verticalNavigationMenuItemActiveOptions]="{exact: subItem.exact}">
                <span>{{subItem?.label}}</span>
              </a>
              <ng-template #keyEYContacts>
                <a motif-vertical-navigation-menu-link [href]="subItem.url" vertical-navigation-menu-item-active
                  [verticalNavigationMenuItemActiveOptions]="{exact: subItem.exact}">
                  <span>{{subItem?.label}}</span>
                </a>
              </ng-template>
            </li>
            <li motif-vertical-navigation-menu-item>
              <a motif-vertical-navigation-menu-link vertical-navigation-menu-item-active (click)="toggletemplates()">
                <span class="templateiconspan" motif-tooltip="Click to see the templates available"
                  tooltip-color="alternate" [hideIconClose]="true" [hideArrow]="true" placement="auto">Templates</span>
                <a *ngIf="!showTemplates" class="templateicon" motif-tooltip="Expand templates"
                  tooltip-color="alternate" [hideIconClose]="true" [hideArrow]="true" placement="auto">+</a>
                <a *ngIf="showTemplates" class="templateicon" motif-tooltip="Collapse templates"
                  tooltip-color="alternate" [hideIconClose]="true" [hideArrow]="true" placement="auto">&ndash;</a>
              </a>
            </li>
            <li motif-vertical-navigation-menu-item *ngIf="showTemplates">
              <a motif-vertical-navigation-menu-link vertical-navigation-menu-item-active
                href="assets/New Product Launch Readiness Assessment General template.pptx" class="quicklinkfont">
                <span class="childpadding">IPOE readiness assessment report general template</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
    </ng-template>
  </ul>
</nav>